/**
 * Table of Contents
 *
 * 1.0 - Reset
 * 2.0 - Header
 * 3.0 - Footer
 * 4.0 - Blog
 * 5.0 - Widget
 * 6.0 - Responsive
 * 7.0 - Plugins
 * 	   	- mmenu
 *		- superfish
 */


/**
 * 1.0 - Reset
 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
	border: 0;
	outline: 0;
	vertical-align: baseline;
	background: transparent;
	margin: 0;
	padding: 0;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
	display: block;
}

nav ul {
	list-style: none;
}

ol {
	list-style: decimal;
}

ul {
	list-style: disc;
}

ul ul {
	list-style: circle;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: none;
}

ins {
	text-decoration: underline;
}

del {
	text-decoration: line-through;
}

mark {
	background: none;
}

abbr[title],
dfn[title],
acronym[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input[type=submit],
input[type=button],
button {
	margin: 0;
	padding: 0;
}

input,
select,
a img {
	vertical-align: middle;
}

b,
strong {
	font-weight: bold;
}

i,
em {
	font-style: italic;
}

html {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	-moz-box-sizing: inherit;
	-webkit-box-sizing: inherit;
}


/* generic class */

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-wrap: break-word;
	word-break: break-word;
}

p {
	margin-bottom: 20px;
}

ul,
ol {
	margin-left: 35px;
	margin-bottom: 20px;
}

dl {
	margin-bottom: 20px;
}

dl dt {
	font-weight: 700;
	margin-top: 10px;
}

dl dt:first-child {
	margin-top: 0px;
}

dl dd {
	margin-left: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
	margin-bottom: 20px;
}

figure {
	max-width: 100%;
}

pre {
	overflow: auto;
	padding: 10px 10px;
	background: #f3f3f3;
	margin-bottom: 20px;
}

.clear {
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
	zoom: 1;
}

.clearfix {
	zoom: 1;
}

.clearfix:after {
	content: " ";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}


/* wordpress core */

.alignnone {
	margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 5px auto 5px auto;
}

.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}

.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}

a img.alignnone {
	margin: 5px 20px 20px 0;
}

a img.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto
}

.wp-caption {
	text-align: center;
	max-width: 100%;
}

.wp-caption.alignnone {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
	margin: 5px 0 20px 20px;
}

.wp-caption img {
	border: 0 none;
	height: auto;
	margin: 0;
	max-width: 98.5%;
	padding: 0;
	width: auto;
}

.wp-caption p.wp-caption-text {
	font-size: 11px;
	line-height: 17px;
	margin: 0;
	padding: 0 4px 5px;
}

figcaption.wp-caption-text {
	margin-top: 20px;
}

.bypostauthor {
	max-width: 100%;
}

.gallery-caption {
	max-width: 100%;
}

.sticky {
	max-width: 100%;
}

.single-format-gallery figcaption.wp-caption-text {
	margin-bottom: 20px;
}

.single-format-gallery figure.gallery-item {
	margin-bottom: 20px;
}

.navigation {
	text-align: right;
	margin-bottom: 30px;
}

.navigation .screen-reader-text {
	display: none;
}

.post-password-form input[type="password"] {
	padding: 14px 20px;
	margin-left: 5px;
	margin-right: 6px;
}

body .page-links>a,
body .nav-links>a,
body .nav-links>span.dots {
	background-color: #f0f0f0;
	color: #919191;
}

body .page-links>span,
body .page-links>a:hover,
body .nav-links>span.current,
body .nav-links>a:hover {
	background-color: #3db166;
	color: #fff;
}

.page-links {
	clear: left;
	margin-top: 40px;
}

.page-links>span,
.page-links>a {
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	padding: 9px 14px;
	margin-right: 7px;
	font-weight: bold;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.nav-links {
	clear: left;
}

.nav-links>span,
.nav-links>a {
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	padding: 9px 14px;
	margin-right: 3px;
	font-weight: bold;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}


/* layout */

.kingster-body-outer-wrapper {
	position: relative;
	overflow: hidden;
}

.kingster-body-wrapper.kingster-with-frame {
	position: relative;
}

.kingster-page-wrapper {
	clear: both;
}

body.kingster-boxed .kingster-body-wrapper {
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

body.kingster-boxed .kingster-body-wrapper.kingster-left {
	margin-left: 0px;
}

body.kingster-boxed .kingster-body-wrapper.kingster-right {
	margin-right: 0px;
}

body.kingster-boxed.kingster-boxed-border .kingster-body-wrapper {
	box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.25);
}

body.kingster-boxed.kingster-background-pattern {
	background-position: center;
	background-repeat: repeat;
}

.kingster-body-background {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background-position: center;
	background-size: cover;
}

.kingster-container {
	margin-left: auto;
	margin-right: auto;
}

.kingster-column-10 {
	float: left;
	width: 16.6666%;
}

.kingster-column-12 {
	float: left;
	width: 20%;
}

.kingster-column-15 {
	float: left;
	width: 25%;
}

.kingster-column-20 {
	float: left;
	width: 33.3333%;
}

.kingster-column-24 {
	float: left;
	width: 40%;
}

.kingster-column-30 {
	float: left;
	width: 50%;
}

.kingster-column-36 {
	float: left;
	width: 60%;
}

.kingster-column-40 {
	float: left;
	width: 66.6666%;
}

.kingster-column-45 {
	float: left;
	width: 75%;
}

.kingster-column-48 {
	float: left;
	width: 80%;
}

.kingster-column-50 {
	float: left;
	width: 83.3333%;
}

.kingster-column-60 {
	float: left;
	width: 100%;
}

.kingster-sidebar-right {
	float: right;
}

.kingster-sidebar-center {
	float: none;
	display: inline-block;
}

.kingster-content-area {
	padding-top: 60px;
	padding-bottom: 40px;
}

.kingster-sidebar-area {
	padding-top: 60px;
}

.kingster-line-height-0 {
	line-height: 0;
}

a,
button {
	text-decoration: none;
	transition: background 300ms, color 300ms, border-color 300ms;
	-moz-transition: background 300ms, color 300ms, border-color 300ms;
	-o-transition: background 300ms, color 300ms, border-color 300ms;
	-webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

img {
	max-width: 100%;
	height: auto;
}

blockquote {
	quotes: none;
	padding: 30px 30px 15px;
	font-size: 17px;
	margin-bottom: 35px;
	font-style: italic;
	margin-top: 35px;
	border-left-width: 2px;
	border-left-style: solid;
}

.kingster-blockquote-style-2 blockquote {
	font-size: 20px;
	font-weight: 800;
	padding-top: 10px;
	padding-left: 35px;
	padding-right: 0px;
	font-style: normal;
	padding-bottom: 10px;
	border-left-width: 4px;
}

.kingster-blockquote-style-2 blockquote p {
	margin-bottom: 0px;
}

q {
	margin-right: 15px;
	display: inline-block;
	padding: 10px 20px;
	vertical-align: middle;
	border-left-width: 2px;
	border-left-style: solid;
}

.kingster-media-image {
	line-height: 0;
	position: relative;
}

.kingster-left-align {
	text-align: left;
}

.kingster-center-align {
	text-align: center;
}

.kingster-right-align {
	text-align: right;
}

.kingster-body-wrapper {
	/* overflow: hidden; */
	z-index: 1;
	position: relative;
}


/* input */

input,
textarea {
	border-width: 1px 1px 2px 1px;
	border-style: solid;
}

input:focus,
textarea:focus {
	outline: none;
}


/* button */

.kingster-button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.8px;
	padding: 15px 33px;
	display: inline-block;
	-webkit-appearance: none;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
	border-width: 0px;
	border-style: solid;
	cursor: pointer;
	transition: background 300ms, color 300ms, border-color 300ms;
	-moz-transition: background 300ms, color 300ms, border-color 300ms;
	-o-transition: background 300ms, color 300ms, border-color 300ms;
	-webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

input[type="button"]:focus,
input[type="submit"]:focus,
input[type="reset"]:focus {
	outline: none;
}


/**
 * 2.0 - Header
 */


/* preload */

.kingster-page-preload {
	position: fixed;
	z-index: 999999;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background-color: #fff;
	background-image: url('http://max-themes.net/demos/kingster/images/preload.gif');
	background-position: center;
	background-repeat: no-repeat;
}

.kingster-page-preload.kingster-out {
	background-image: none;
}


/* top bar */

.kingster-top-bar {
	position: relative;
	border-bottom-style: solid;
}

.kingster-top-bar-background {
	position: absolute;
	right: 0px;
	left: 0px;
	top: 0px;
	bottom: 0px;
}

.kingster-top-bar-container {
	margin-left: auto;
	margin-right: auto;
}

.kingster-top-bar-container-inner {
	position: relative;
}

.kingster-top-bar-left {
	float: left;
}

.kingster-top-bar-right {
	float: right;
}

.kingster-top-bar-right-text {
	display: inline;
	margin-left: 23px;
}

.kingster-top-bar-right-social {
	display: inline;
}

.kingster-top-bar-right-social a {
	margin-left: 25px;
}

.kingster-body .kingster-top-bar .kingster-top-bar-right-button {
	background: #3db166;
	color: #fff;
}

.kingster-body .kingster-top-bar .kingster-top-bar-right-button {
	display: inline-block;
	font-weight: bold;
	padding-left: 23px;
	padding-right: 23px;
	margin-left: 23px;
}


/* top bar menu */

.kingster-top-bar .kingster-top-bar-menu {
	display: inline-block;
	margin-bottom: 0px;
}

.kingster-top-bar .kingster-top-bar-menu>li {
	float: none;
	display: inline-block;
}

.kingster-top-bar .kingster-top-bar-menu>li>a {
	display: inline;
	margin-right: 16px;
}

.kingster-top-bar .kingster-top-bar-menu>li:last-child>a {
	margin-right: 0px;
}

.kingster-top-bar .kingster-top-bar-left-menu {
	margin-right: 23px;
}

.kingster-top-bar .kingster-top-bar-right-menu {
	margin-left: 23px;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu ul {
	margin-left: 0px;
	font-size: 13px;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu ul ul {
    margin-top: 0px;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu:first-child ul {
	margin-left: -17px;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu ul li {
	padding: 0px 18px 10px;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu ul li:first-child {
	padding-top: 10px;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu ul li>a {
	background: transparent;
}

.sf-menu.kingster-top-bar-menu>.kingster-normal-menu ul li>a:after {
	margin-left: 10px;
}

.sf-menu.kingster-top-bar-menu>.kingster-mega-menu .sf-mega-full {
	left: 0px;
	right: 0px;
	padding-top: 18px;
	padding-bottom: 8px;
}

.sf-menu.kingster-top-bar-menu>.kingster-mega-menu .sf-mega-section-inner ul {
	font-size: 13px;
}

.sf-menu.kingster-top-bar-menu>.kingster-mega-menu .sf-mega-section-inner ul li {
	padding: 0px 18px 10px;
}


/* mobile menu */

.kingster-mm-menu-wrap {
	display: none;
}

.admin-bar .mm-menu {
	margin-top: 32px;
}

.kingster-mobile-button-hamburger-with-border {
	font-size: 16px;
	padding: 6px;
	line-height: 0;
	width: 32px;
	height: 32px;
	text-align: center;
	display: inline-block;
	border-width: 2px;
	border-style: solid;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.kingster-right-menu-button {
	display: inline-block;
	margin-left: 27px;
}

.kingster-mobile-menu {
	float: left;
	margin-top: -8px;
	margin-left: 30px;
	z-index: 10000;
}

.kingster-mobile-menu-right {
	position: absolute;
	right: 30px;
	top: 50%;
	margin-top: -9px;
}

.kingster-mobile-menu-left {
	position: absolute;
	left: 30px;
	top: 50%;
	margin-top: -9px;
}

.kingster-mobile-menu-left .kingster-mobile-menu {
	margin-left: 0px;
}

.kingster-mobile-header .kingster-mobile-logo-center .kingster-logo-inner {
	margin-left: auto;
	margin-right: auto;
}


/* hamburger */

.kingster-mobile-button-hamburger {
	box-sizing: border-box;
	display: block;
	width: 32px;
	height: 32px;
	position: relative;
}

.kingster-mobile-button-hamburger:before,
.kingster-mobile-button-hamburger:after,
.kingster-mobile-button-hamburger span {
	content: '';
	display: block;
	width: 22px;
	height: 2px;
	position: absolute;
	left: 5px;
}

.kingster-mobile-button-hamburger:before {
	top: 8px;
}

.kingster-mobile-button-hamburger span {
	top: 15px;
}

.kingster-mobile-button-hamburger:after {
	top: 22px;
}

.kingster-mobile-button-hamburger:before {
	transition-duration: 0.4s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.kingster-mobile-button-hamburger span {
	transition: opacity 0.15s 0.4s ease;
}

.kingster-mobile-button-hamburger:after {
	transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.kingster-mobile-button-hamburger.kingster-active:before {
	-webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
	transform: translate3d(0, 7px, 0) rotate(135deg);
	transition-delay: 0.1s;
}

.kingster-mobile-button-hamburger.kingster-active span {
	transition-delay: 0s;
	opacity: 0;
}

.kingster-mobile-button-hamburger.kingster-active:after {
	transition-delay: 0.1s;
	-webkit-transform: translate3d(0, -7px, 0) rotate(-135deg);
	transform: translate3d(0, -7px, 0) rotate(-135deg);
}


/* navigation menu */

.kingster-navigation .sf-menu {
	display: inline-block;
	line-height: 1.7;
	text-align: left;
	margin-bottom: 0px;
}

.kingster-navigation .sf-menu>li:first-child {
	padding-left: 0px;
}

.kingster-navigation .sf-menu>li:last-child {
	padding-right: 0px;
}

.kingster-navigation .sf-menu>li>a {
	letter-spacing: 0px;
}

.kingster-navigation-submenu-indicator .sf-menu>li>a.sf-with-ul-pre:after {
	display: inline-block;
	margin-left: 13px;
	content: "\f107";
	font-family: fontAwesome;
}

.kingster-navigation .sf-menu>.kingster-normal-menu li {
	padding: 1px 11px;
}

.kingster-navigation .sf-menu>.kingster-normal-menu li:first-child {
	padding-top: 12px;
}

.kingster-navigation .sf-menu>.kingster-normal-menu li:last-child {
	padding-bottom: 12px;
}

.kingster-navigation .sf-menu>.kingster-normal-menu li li {
	/* padding-left: 0; */
}

.kingster-navigation .sf-menu>.kingster-normal-menu li>a {
	display: block;
	padding: 6px 30px 6px 12px;
	position: relative;
}

.kingster-navigation .sf-menu>.kingster-normal-menu li>a.sf-with-ul-pre:after {
	position: absolute;
	right: 10px;
	content: "\f105";
	font-family: fontAwesome;
}

.kingster-navigation .sf-menu>.kingster-normal-menu .sub-menu {
	font-size: 14px;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-full {
	left: 0px;
	right: 0px;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section-wrap {
	overflow: hidden;
	border-top-width: 1px;
	border-top-style: solid;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section-wrap:first-child {
	border-top: none;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section {
	padding-bottom: 999px;
	margin-bottom: -999px;
	border-left-width: 1px;
	border-left-style: solid;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section:first-child {
	border-left: none;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section-inner {
	padding: 45px 28px 45px;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section-inner>a {
	font-size: 17px;
	display: block;
	margin-bottom: 17px;
	letter-spacing: 0px;
	font-weight: 600;
	padding: 0px 12px;
}

.kingster-navigation .sf-menu>.kingster-mega-menu .sf-mega-section-inner .sub-menu a {
	font-size: 15px;
	padding: 4px 12px;
	display: block;
}

.kingster-navigation .kingster-navigation-slide-bar {
	border-bottom-width: 7px;
	border-bottom-style: solid;
	position: absolute;
	display: none;
	margin-top: -7px;
	overflow: hidden;
}

.kingster-navigation .sf-menu a i {
	margin-right: 10px;
}

.kingster-navigation .sf-vertical>li {
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.kingster-navigation .sf-vertical>li:first-child {
	padding-top: 0px;
}

.kingster-navigation .sf-vertical>li>a {
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	display: block;
}

.kingster-navigation .sf-vertical ul.sub-menu {
	font-size: 14px;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

.kingster-navigation .sf-vertical ul.sub-menu li {
	padding: 2px 11px;
}

.kingster-navigation .sf-vertical ul.sub-menu li:first-child {
	padding-top: 20px;
}

.kingster-navigation .sf-vertical ul.sub-menu li:last-child {
	padding-bottom: 20px;
}

.kingster-navigation .sf-vertical ul.sub-menu li>a {
	display: block;
	padding: 4px 12px;
}

.kingster-navigation .sf-vertical ul.sub-menu li>a.sf-with-ul:after,
.kingster-navigation-submenu-indicator .sf-vertical>li>a.sf-with-ul:after {
	float: right;
	margin-left: 18px;
	content: "\f105";
	font-family: fontAwesome;
}


/* bullet anchor */

.kingster-bullet-anchor {
	position: fixed;
	top: 50%;
	right: 30px;
	margin-top: -50px;
	z-index: 10;
	opacity: 0;
	transition: opacity 200ms;
	-moz-transition: opacity 200ms;
	-o-transition: opacity 200ms;
	-webkit-transition: opacity 200ms;
}

.kingster-bullet-anchor.kingster-init {
	opacity: 1;
}

.kingster-bullet-anchor a {
	display: block;
	width: 17px;
	height: 17px;
	margin-bottom: 5px;
	overflow: hidden;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.kingster-bullet-anchor a:before {
	content: " ";
	width: 9px;
	height: 9px;
	margin: 3px;
	display: block;
	overflow: hidden;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	transition: background-color 200ms;
	-moz-transition: background-color 200ms;
	-o-transition: background-color 200ms;
	-webkit-transition: background-color 200ms;
}


/* breadcrumbs */

.kingster-breadcrumbs {
	font-size: 15px;
}

.kingster-breadcrumbs a {
	color: #c0c0c0;
}

.kingster-breadcrumbs,
.kingster-breadcrumbs a:hover {
	color: #777;
}

.kingster-breadcrumbs {
	font-size: 14px;
}

.kingster-breadcrumbs span[property="itemListElement"] {
	margin: 0px 15px;
}

.kingster-breadcrumbs span[property="itemListElement"]:first-child {
	margin-left: 0px;
}

.kingster-breadcrumbs a span {
	transition: color 200ms;
	-moz-transition: color 200ms;
	-o-transition: color 200ms;
	-webkit-transition: color 200ms;
}


/* top search */

.kingster-top-search-wrap input.search-field {
	border-bottom-color: #303030;
	color: #fff;
}

.kingster-top-search-wrap .kingster-top-search-submit,
.kingster-top-search-wrap .kingster-top-search-close {
	color: #fff;
}

.kingster-top-search-wrap input::-webkit-input-placeholder {
	color: #fff;
}

.kingster-top-search-wrap input::-moz-placeholder {
	color: #fff;
}

.kingster-top-search-wrap input:-ms-input-placeholder {
	color: #fff;
}

.kingster-top-search-wrap input:-moz-placeholder {
	color: #fff;
}

.kingster-top-search-row {
	display: table;
	width: 100%;
	height: 100%;
}

.kingster-top-search-cell {
	display: table-cell;
	vertical-align: middle;
	padding-left: 30px;
	padding-right: 30px;
}

.kingster-top-search-wrap {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 9999;
	overflow-y: auto;
	display: none;
}

.kingster-top-search-wrap form {
	max-width: 630px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.kingster-top-search-wrap input.search-field {
	background: transparent;
	width: 100%;
	border-width: 0px 0px 3px;
	font-size: 40px;
	padding: 0px 120px 25px 0px;
	line-height: 48px;
	letter-spacing: 0.5px;
}

.kingster-top-search-wrap input.search-submit {
	position: absolute;
	top: 0px;
	right: 52px;
	width: 48px;
	height: 48px;
	padding: 0px;
	opacity: 0;
	filter: alpha(opacity=0);
}

.kingster-top-search-wrap .kingster-top-search-submit {
	font-size: 30px;
	position: absolute;
	right: 52px;
	top: 0px;
	line-height: 48px;
	width: 48px;
	text-align: center;
}

.kingster-top-search-wrap .kingster-top-search-close {
	font-size: 55px;
	position: absolute;
	right: 0px;
	top: -4px;
	line-height: 48px;
	width: 48px;
	text-align: center;
	cursor: pointer;
}


/* overlay menu */

.kingster-overlay-menu-row {
	display: table;
	width: 100%;
	height: 100%;
}

.kingster-overlay-menu-cell {
	display: table-cell;
	vertical-align: middle;
}

.kingster-overlay-menu-content {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 9999;
	overflow-y: auto;
	display: none;
}

.kingster-overlay-menu-content ul.menu {
	list-style: none;
	text-align: center;
	padding-top: 180px;
	padding-bottom: 140px;
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px;
}

.kingster-overlay-menu-content ul.menu>li {
	font-size: 23px;
	font-weight: bold;
	text-transform: uppercase;
	opacity: 0;
	margin-bottom: 17px;
	padding-bottom: 17px;
	letter-spacing: 1px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	-webkit-transform: translate3d(0, -30px, 0);
	transform: translate3d(0, -30px, 0);
	transition: opacity 300ms, transform 300ms;
	-moz-transition: opacity 300ms, -moz-transform 300ms;
	-o-transition: opacity 300ms, -o-transform 300ms;
	-webkit-transition: opacity 300ms, -webkit-transform 300ms;
}

.kingster-overlay-menu-content.kingster-active ul.menu>li {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: none;
	transform: none;
}

.kingster-overlay-menu-content ul.sub-menu {
	display: none;
	padding-top: 25px;
	padding-bottom: 10px;
	list-style: none;
	margin-left: 0px;
	margin-bottom: 0px;
}

.kingster-overlay-menu-content ul.sub-menu>li {
	font-size: 14px;
	text-transform: none;
	font-weight: normal;
	margin-bottom: 15px;
}

.kingster-overlay-menu-content ul.sub-menu ul.sub-menu {
	max-width: 380px;
	margin: 25px auto 25px;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-style: solid;
}

.kingster-overlay-menu-content .kingster-overlay-menu-close {
	line-height: 1;
	font-size: 48px;
	position: absolute;
	left: 50%;
	top: 75px;
	margin-left: -24px;
	cursor: pointer;
}

.kingster-overlay-menu-content .kingster-overlay-menu-close:after {
	content: "\4d";
	font-family: elegantIcons;
}

.kingster-overlay-menu-content ul li.menu-item-has-children>a:after {
	content: "\f107";
	font-family: "fontAwesome";
	margin-left: 15px;
}

body.admin-bar .kingster-overlay-menu-content {
	margin-top: 32px;
	overflow-y: scroll;
}


/* menu right area */

.kingster-main-menu-right-button {
	float: left;
	padding: 8px 15px;
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: -11px;
	margin-left: 30px;
	border-radius: 3px;
	border-width: 2px;
	border-style: solid;
}

.kingster-main-menu-right-button.kingster-style-round {
	padding: 13px 21px 14px;
	margin-top: -14px;
	border-radius: 30px;
	-moz-border-radius: 30px;
	-webkit-border-radius: 30px;
}

.kingster-main-menu-right-button.kingster-style-round-with-shadow {
	padding: 13px 21px 14px;
	margin-top: -14px;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
	-webkit-box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
	border-radius: 30px;
	-moz-border-radius: 30px;
	-webkit-border-radius: 30px;
}

.kingster-main-menu-right {
	float: left;
	margin-top: -8px;
}

.kingster-main-menu-search {
	float: left;
	margin-top: -5px;
	margin-left: 25px;
}

.kingster-main-menu-search i {
	font-size: 14px;
	margin-top: -1px;
	cursor: pointer;
}

.kingster-main-menu-cart {
	float: left;
	margin-top: -4px;
	padding-left: 20px;
	margin-left: 5px;
	position: relative;
}

.kingster-main-menu-cart i {
	font-size: 16px;
	margin-top: -1px;
	cursor: pointer;
}

.kingster-main-menu-cart>.kingster-top-cart-count {
	font-size: 10px;
	font-weight: 700;
	vertical-align: super;
	width: 16px;
	height: 16px;
	line-height: 15px;
	position: absolute;
	top: -5px;
	right: -8px;
	text-align: center;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.kingster-top-cart-content-wrap {
	position: absolute;
	width: 210px;
	right: 0px;
	top: 100%;
	padding-top: 24px;
	z-index: 99;
}

.kingster-top-cart-content-wrap .kingster-top-cart-content {
	padding: 25px;
	text-align: left;
	font-size: 16px;
	font-weight: 700;
}

.kingster-top-cart-content-wrap .woocommerce-Price-amount.amount {
	float: none;
}

.kingster-top-cart-content-wrap .kingster-top-cart-count-wrap {
	margin-bottom: 8px;
}

.kingster-top-cart-content-wrap .kingster-top-cart-amount-wrap {
	margin-bottom: 23px;
}

.kingster-top-cart-content-wrap .kingster-top-cart-button {
	display: block;
	margin-bottom: 5px;
}

.kingster-top-cart-content-wrap .kingster-top-cart-checkout-button {
	display: block;
}

.kingster-main-menu-cart .kingster-top-cart-hover-area {
	position: absolute;
	top: -20px;
	right: 0px;
	bottom: -20px;
	left: 0px;
}

.kingster-top-cart-hover-area+.kingster-top-cart-content-wrap {
	opacity: 0;
	visibility: hidden;
	transform: translate3d(0, 15px, 0);
	-webkit-transform: translate3d(0, 15px, 0);
	transition: opacity 400ms, transform 400ms;
	-moz-transition: opacity 400ms, -moz-transform 400ms;
	-o-transition: opacity 400ms, -o-transform 400ms;
	-webkit-transition: opacity 400ms, -webkit-transform 400ms;
}

.kingster-main-menu-cart:hover .kingster-top-cart-content-wrap,
.kingster-top-cart-hover-area:hover+.kingster-top-cart-content-wrap {
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0px, 0);
	-webkit-transform: translate3d(0, 0px, 0);
}


/* header */

.kingster-logo {
	position: relative;
}

.kingster-header-container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
}

.kingster-header-container-inner {
	position: relative;
}


/* header plain */

.kingster-header-style-plain {
	position: relative;
	border-bottom-style: solid;
}

.kingster-header-style-plain .kingster-navigation {
	line-height: 0;
}

.kingster-header-style-plain .kingster-header-background {
	position: absolute;
	right: 0px;
	left: 0px;
	top: 0px;
	bottom: 0px;
}

.kingster-header-background-transparent {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 3;
}

.kingster-header-style-plain.kingster-style-menu-right .kingster-logo {
	float: left;
}

.kingster-header-style-plain.kingster-style-menu-right .kingster-navigation {
	text-align: center;
}

.kingster-header-style-plain.kingster-style-menu-right .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-plain.kingster-style-menu-right .kingster-main-menu-right-wrap {
	display: inline-block;
	line-height: 1.7;
	margin-left: 10px;
	vertical-align: top;
}

.kingster-header-style-plain.kingster-style-center-logo .kingster-logo-inner {
	margin-left: auto;
	margin-right: auto;
}

.kingster-header-style-plain.kingster-style-center-logo .kingster-navigation {
	text-align: center;
	position: relative;
}

.kingster-header-style-plain.kingster-style-center-logo .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-plain.kingster-style-center-logo .kingster-main-menu-right-wrap {
	position: absolute;
	right: 0px;
	line-height: 1.7;
}

.kingster-header-style-plain.kingster-style-center-menu .kingster-logo {
	position: absolute;
}

.kingster-header-style-plain.kingster-style-center-menu .kingster-navigation {
	text-align: center;
}

.kingster-header-style-plain.kingster-style-center-menu .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-plain.kingster-style-center-menu .kingster-main-menu-right-wrap {
	position: absolute;
	right: 0px;
	line-height: 1.7;
}

.kingster-header-style-plain.kingster-style-splitted-menu .kingster-logo {
	padding-top: 0px;
	padding-bottom: 0px;
}

.kingster-header-style-plain.kingster-style-splitted-menu .kingster-navigation {
	text-align: center;
}

.kingster-header-style-plain.kingster-style-splitted-menu .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-plain.kingster-style-splitted-menu .kingster-main-menu-right-wrap {
	position: absolute;
	right: 0px;
	line-height: 1.7;
}

.kingster-header-style-plain.kingster-style-splitted-menu .kingster-main-menu-left-wrap {
	position: absolute;
	left: 0px;
	line-height: 1.7;
}

.kingster-header-style-plain.kingster-style-splitted-menu .kingster-right-menu-button {
	margin-left: 0px;
}


/* header box */

.kingster-header-boxed-wrap {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 3;
}

.kingster-header-style-boxed .kingster-header-container-item {
	position: relative;
}

.kingster-header-style-boxed .kingster-navigation {
	line-height: 0;
}

.kingster-header-style-boxed .kingster-header-background {
	position: absolute;
	right: 0px;
	left: 0px;
	top: 0px;
	bottom: 0px;
}

.kingster-header-style-boxed.kingster-style-menu-right .kingster-logo {
	float: left;
}

.kingster-header-style-boxed.kingster-style-menu-right .kingster-navigation {
	float: right;
}

.kingster-header-style-boxed.kingster-style-menu-right .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-boxed.kingster-style-menu-right .kingster-main-menu-right-wrap {
	display: inline-block;
	line-height: 1.7;
	margin-left: 10px;
	vertical-align: top;
}

.kingster-header-style-boxed.kingster-style-center-menu .kingster-logo {
	position: absolute;
}

.kingster-header-style-boxed.kingster-style-center-menu .kingster-navigation {
	text-align: center;
}

.kingster-header-style-boxed.kingster-style-center-menu .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-boxed.kingster-style-center-menu .kingster-main-menu-right-wrap {
	position: absolute;
	line-height: 1.7;
}

.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-logo {
	padding-top: 0px;
	padding-bottom: 0px;
}

.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-navigation {
	text-align: center;
}

.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-main-menu-right-wrap {
	position: absolute;
	line-height: 1.7;
}

.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-main-menu-left-wrap {
	position: absolute;
	line-height: 1.7;
}

.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-right-menu-button {
	margin-left: 0px;
}


/* header bar */

.kingster-header-style-bar .kingster-logo {
	float: left;
}

.kingster-header-style-bar .kingster-logo-right-text {
	float: right;
	text-align: right;
	font-size: 13px;
}

.kingster-header-style-bar .kingster-header-container-inner {
	position: relative;
}

.kingster-navigation-bar-wrap {
	border-top-width: 1px;
	border-top-style: solid;
	position: relative;
}

.kingster-navigation-bar-wrap.kingster-style-transparent {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 3;
}

.kingster-navigation-bar-wrap .kingster-navigation {
	line-height: 0;
	position: relative;
}

.kingster-navigation-bar-wrap .kingster-navigation-background {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.kingster-navigation-bar-wrap .kingster-navigation-container {
	position: relative;
}

.kingster-navigation-bar-wrap .kingster-main-menu {
	display: inline-block;
	vertical-align: top;
}

.kingster-navigation-bar-wrap.kingster-style-left .kingster-main-menu-right-wrap {
	float: right;
	line-height: 1.7;
	margin-left: 10px;
}

.kingster-navigation-bar-wrap.kingster-style-center {
	text-align: center;
}

.kingster-navigation-bar-wrap.kingster-style-center .kingster-main-menu-right-wrap {
	position: absolute;
	right: 0px;
	line-height: 1.7;
}

.kingster-header-style-bar.kingster-style-center .kingster-logo {
	float: none;
}

.kingster-header-style-bar.kingster-style-center .kingster-logo-inner {
	margin-left: auto;
	margin-right: auto;
}

.kingster-header-style-bar.kingster-style-center .kingster-logo-right-text {
	position: absolute;
	top: 0px;
	right: 0px;
}


/* header side */

.kingster-header-side-content {
	position: relative;
	z-index: 98;
}

.kingster-header-side-nav {
	position: fixed;
	top: 0px;
	bottom: 0px;
	z-index: 99;
}

.kingster-header-side-nav.kingster-allow-slide {
	position: absolute;
	bottom: auto;
}

.kingster-header-side-nav.kingster-fix-bottom {
	position: fixed;
	top: auto;
	bottom: 0px;
}

.kingster-header-side-nav.kingster-fix-top {
	position: fixed;
}

.kingster-header-side-nav.kingster-style-left {
	left: 0px;
}

.kingster-header-side-nav.kingster-style-right {
	right: 0px;
}

.kingster-header-side-nav .kingster-pos-bottom,
.kingster-header-side-nav.kingster-style-middle .kingster-pos-middle {
	opacity: 0;
	filter: alpha(opacity=0);
	transition: opacity 300ms;
	-moz-transition: opacity 300ms;
	-o-transition: opacity 300ms;
	-webkit-transition: opacity 300ms;
}

.kingster-header-side-nav .kingster-pos-bottom.kingster-active,
.kingster-header-side-nav.kingster-style-middle .kingster-pos-middle.kingster-active {
	opacity: 1;
	filter: alpha(opacity=100);
}

.kingster-header-side-nav.kingster-style-side .kingster-header-social {
	padding-top: 20px;
	padding-bottom: 40px;
}

.kingster-header-side-nav.kingster-style-side .kingster-header-social a {
	margin-left: 25px;
}

.kingster-header-side-nav.kingster-style-side .kingster-header-social a:first-child {
	margin-left: 0px;
}

.kingster-header-side-nav.kingster-style-side .kingster-main-menu-right-wrap {
	padding-top: 22px;
}

.kingster-header-side-nav.kingster-style-side .kingster-main-menu-search {
	float: none;
	display: inline-block;
	margin-top: 0px;
	margin-left: 20px;
}

.kingster-header-side-nav.kingster-style-side .kingster-main-menu-cart {
	float: none;
	display: inline-block;
	margin-top: 0px;
	margin-left: 0px;
}

.kingster-header-side-nav.kingster-style-side .kingster-main-menu-right-wrap>div:first-child {
	margin-left: 0px;
}

.kingster-header-side-nav.kingster-style-left .sf-vertical ul.sub-menu {
	text-align: left;
	margin-left: 0px;
}

.kingster-header-side-nav.kingster-style-right .sf-vertical ul.sub-menu {
	left: auto;
	right: 100%;
	text-align: right;
}

.kingster-header-side-nav.kingster-style-right .sf-vertical ul.sub-menu li>a.sf-with-ul:after {
	content: "\f104";
	float: left;
	margin-left: 0px;
	margin-right: 18px;
}

.kingster-header-style-side.kingster-center-align .kingster-logo-inner {
	margin-right: auto;
	margin-left: auto;
}

.kingster-header-style-side.kingster-right-align .kingster-logo-inner {
	margin-right: 0px;
	margin-left: auto;
}

.kingster-header-side-nav.kingster-style-side-toggle {
	text-align: center;
}

.kingster-header-side-nav.kingster-style-side-toggle .kingster-logo-inner {
	margin-left: auto;
	margin-right: auto;
}

.kingster-header-side-nav.kingster-style-side-toggle .kingster-main-menu-search,
.kingster-header-side-nav.kingster-style-side-toggle .kingster-main-menu-cart {
	float: none;
	margin-bottom: 8px;
	padding-left: 0px;
	margin-left: 0px;
	margin-top: 0px;
}

.kingster-header-side-nav.kingster-style-side-toggle .kingster-header-icon {
	padding-bottom: 20px;
	padding-top: 30px;
}

body.admin-bar .kingster-header-side-nav {
	padding-top: 32px;
}

html.mm-opened body.admin-bar .kingster-header-side-nav {
	padding-top: 0px;
}

.kingster-header-style-side .kingster-top-cart-content-wrap {
	right: auto;
	left: 34px;
	bottom: 6px;
	top: auto;
}


/* fixed nav bar */

.kingster-logo-inner {
	transition: max-width 200ms;
	-moz-transition: max-width 200ms;
	-o-transition: max-width 200ms;
	-webkit-transition: max-width 200ms;
}

.kingster-fixed-nav-logo {
	display: none;
}

.kingster-sticky-navigation.kingster-fixed-navigation .kingster-fixed-nav-logo {
	display: block;
}

.kingster-sticky-navigation.kingster-fixed-navigation .kingster-orig-logo {
	display: none;
}

.kingster-sticky-navigation.kingster-style-fixed .kingster-logo,
.kingster-sticky-navigation.kingster-style-fixed .kingster-navigation,
.kingster-sticky-navigation.kingster-style-fixed .kingster-navigation .sf-menu>li>a {
	transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
	-moz-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
	-o-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
	-webkit-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
}

.kingster-sticky-navigation.kingster-style-fixed .kingster-navigation-top {
	transition: top 200ms;
	-moz-transition: top 200ms;
	-o-transition: top 200ms;
	-webkit-transition: top 200ms;
}

.kingster-sticky-navigation.kingster-style-fixed.kingster-animate-fixed-navigation .kingster-logo-inner img {
	transition: height 200ms;
	-moz-transition: height 200ms;
	-o-transition: height 200ms;
	-webkit-transition: height 200ms;
}

.kingster-fixed-navigation.kingster-style-fixed,
.kingster-fixed-navigation.kingster-style-slide {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 10;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.kingster-fixed-navigation.kingster-style-slide .kingster-logo-inner img {
	max-height: 35px !important;
	width: auto;
}

body.kingster-sticky-navigation-no-logo .kingster-fixed-navigation .kingster-logo {
	display: none;
}

body.kingster-sticky-navigation-no-logo .kingster-mobile-header.kingster-fixed-navigation .kingster-logo {
	display: block;
}

body.admin-bar .kingster-fixed-navigation.kingster-style-fixed,
body.admin-bar .kingster-fixed-navigation.kingster-style-slide {
	margin-top: 32px;
}

@media screen and (max-width: 782px) {
	body.admin-bar .kingster-fixed-navigation.kingster-style-slide {
		margin-top: 0px;
	}
}

.kingster-animate-fixed-navigation.kingster-header-style-plain .kingster-navigation-top {
	top: 30px;
}

.kingster-animate-fixed-navigation.kingster-header-style-plain.kingster-style-splitted-menu .kingster-logo {
	margin-top: -10px;
}

.kingster-animate-fixed-navigation.kingster-header-style-plain.kingster-style-splitted-menu .kingster-navigation .sf-menu>li>a {
	padding-top: 0px;
}

.kingster-animate-fixed-navigation.kingster-header-style-plain.kingster-style-splitted-menu .kingster-navigation-top {
	top: 22px;
}

.kingster-animate-fixed-navigation.kingster-header-style-plain.kingster-style-center-logo .kingster-logo {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 3;
}

.kingster-animate-fixed-navigation.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-logo {
	margin-top: -10px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.kingster-animate-fixed-navigation.kingster-header-style-boxed.kingster-style-splitted-menu .kingster-navigation .sf-menu>li>a {
	padding-top: 0px;
}


/* page title */

.kingster-page-title-wrap {
	background-image: url('http://max-themes.net/demos/kingster/images/page-title-background.jpg');
	background-position: center;
	background-size: cover;
	position: relative;
	overflow: hidden;
}

.kingster-page-title-wrap .kingster-page-title-overlay {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.kingster-page-title-wrap .kingster-page-title-top-gradient {
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
}

.kingster-page-title-wrap .kingster-page-title-bottom-gradient {
	position: absolute;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.kingster-page-title-wrap .kingster-page-title-container {
	position: relative;
}

.kingster-page-title-wrap .kingster-page-title {
	text-transform: uppercase;
	letter-spacing: 1px;
}

.kingster-page-title-wrap.kingster-style-small .kingster-page-title {
	font-size: 37px;
	margin-bottom: 0px;
}

.kingster-page-title-wrap.kingster-style-small .kingster-page-caption {
	font-size: 16px;
	margin-bottom: 10px;
}

.kingster-page-title-wrap.kingster-style-small .kingster-page-title-content {
	padding-top: 93px;
	padding-bottom: 87px;
}

.kingster-page-title-wrap.kingster-style-medium .kingster-page-title {
	font-size: 48px;
	margin-bottom: 0px;
}

.kingster-page-title-wrap.kingster-style-medium .kingster-page-caption {
	font-size: 21px;
	margin-bottom: 5px;
}

.kingster-page-title-wrap.kingster-style-medium .kingster-page-title-content {
	padding-top: 126px;
	padding-bottom: 116px;
}

.kingster-page-title-wrap.kingster-style-large .kingster-page-title {
	font-size: 75px;
	margin-bottom: 0px;
}

.kingster-page-title-wrap.kingster-style-large .kingster-page-caption {
	font-size: 25px;
	margin-bottom: 3px;
}

.kingster-page-title-wrap.kingster-style-large .kingster-page-title-content {
	padding-top: 184px;
	padding-bottom: 168px;
}

.kingster-page-title-wrap.kingster-style-custom .kingster-page-title {
	font-size: 37px;
	margin-bottom: 0px;
}

.kingster-page-title-wrap.kingster-style-custom .kingster-page-caption {
	font-size: 16px;
}

.kingster-page-title-wrap .kingster-page-title-event-link {
	font-size: 15px;
	margin-bottom: 10px;
}

.kingster-page-title-wrap .kingster-page-title-event-time {
	margin-top: 6px;
	font-size: 21px;
}


/**
 * 3.0 - Footer
 */


/* footer */

.kingster-footer-wrapper .kingster-footer-container {
	overflow: hidden;
}

.kingster-footer-wrapper .kingster-widget-title {
	font-size: 13px;
	text-transform: uppercase;
	border-bottom-width: 2px;
	border-bottom-style: solid;
	padding-bottom: 15px;
	margin-bottom: 26px;
}

.kingster-footer-wrapper .kingster-widget-title .gdlr-core-flexslider-nav {
	float: right;
	margin-left: 20px;
}

.kingster-footer-wrapper .widget {
	margin-bottom: 60px;
}

.kingster-footer-wrapper .widget:last-child {
	margin-bottom: 0px;
}

.kingster-footer-wrapper.kingster-with-column-divider .kingster-footer-column {
	padding-bottom: 1029px;
	margin-bottom: -1020px;
	border-left: 1px;
	border-style: solid;
	border-color: inherit;
	padding-left: 30px;
	padding-right: 30px;
}

.kingster-footer-wrapper.kingster-with-column-divider .kingster-footer-column:first-child {
	border: none;
}

.kingster-fixed-footer {
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

body.kingster-boxed .kingster-fixed-footer .kingster-footer-wrapper,
body.kingster-boxed .kingster-fixed-footer .kingster-copyright-wrapper {
	margin-left: auto;
	margin-right: auto;
}

.kingster-footer-back-to-top-button {
	position: fixed;
	z-index: 9;
	right: 30px;
	bottom: 30px;
	font-size: 18px;
	line-height: 20px;
	width: 45px;
	height: 45px;
	text-align: center;
	padding: 12px;
	cursor: pointer;
	opacity: 0;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	pointer-events: none;
	transition: opacity 300ms;
	-moz-transition: opacity 300ms;
	-o-transition: opacity 300ms;
	-webkit-transition: opacity 300ms;
}

.kingster-footer-back-to-top-button.kingster-scrolled {
	opacity: 1;
	pointer-events: auto;
}


/* copyright */

.kingster-footer-wrapper {
	padding-top: 70px;
	padding-bottom: 50px;
}

.kingster-copyright-container {
	text-transform: uppercase;
	letter-spacing: 2px;
}

.kingster-copyright-text {
	text-align: center;
	font-size: 14px;
}

.kingster-copyright-left {
	float: left;
}

.kingster-copyright-right {
	float: right;
}


/**
 * 4.0 - Blog
 */


/* single */

.kingster-single-article-date-wrapper {
	float: left;
	width: 58px;
	border-right-width: 1px;
	border-right-style: solid;
	line-height: 1;
	margin-top: 8px;
	margin-right: 30px;
	padding-right: 20px;
	text-align: center;
}

.kingster-single-article-date-wrapper .kingster-single-article-date-day {
	font-size: 27px;
	font-weight: bold;
	margin-bottom: 8px;
	margin-top: -4px;
	letter-spacing: 1px;
	white-space: nowrap;
}

.kingster-single-article-date-wrapper .kingster-single-article-date-month {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.kingster-single-article-date-wrapper .kingster-single-article-date-year {
	font-size: 12px;
	margin-top: 4px;
}

.kingster-single-article .kingster-single-article-content ul,
.kingster-single-article .kingster-single-article-content ol {
	line-height: 2;
}

.kingster-single-article .kingster-single-article-thumbnail {
	margin-bottom: 42px;
}

.kingster-single-article .kingster-single-article-head {
	margin-bottom: 32px;
}

.kingster-single-article .kingster-single-article-title {
	font-size: 37px;
	font-weight: 700;
	margin-bottom: 16px;
	letter-spacing: 0.5px;
}

.kingster-single-article .kingster-single-article-head-right {
	overflow: hidden;
}

.kingster-blog-info-wrapper .kingster-head {
	margin-right: 7px;
	vertical-align: baseline;
}

.kingster-blog-info-wrapper .kingster-head i {
	font-size: 15px;
}

.kingster-blog-info-wrapper .kingster-blog-info {
	display: inline-block;
	margin-right: 12px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.kingster-blog-info-wrapper .kingster-blog-info:before {
	content: "/";
	margin-right: 12px;
}

.kingster-blog-info-wrapper .kingster-blog-info:first-child:before {
	display: none;
}

.kingster-blog-info-wrapper .kingster-blog-info:last-child {
	margin-right: 0px;
}

body.home .kingster-single-article .kingster-single-article-title {
	margin-bottom: 8px;
}

.kingster-sticky-banner {
	line-height: 1;
	display: inline-block;
	letter-spacing: 0.5px;
	padding: 18px 20px 17px;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: bold;
	margin-bottom: 22px;
}

.kingster-sticky-banner i {
	font-size: 12px;
	margin-right: 10px;
}

.kingster-single-article-thumbnail .kingster-sticky-banner {
	position: absolute;
	left: 0px;
	bottom: 0px;
	margin-bottom: 0px;
}

body.single-post .kingster-content-area {
    padding-bottom: 30px;
}

body.single-post .kingster-sidebar-style-none {
	margin-left: auto;
	margin-right: auto;
}

body.blog .kingster-sidebar-style-none {
	margin-left: auto;
	margin-right: auto;
}

body.blog .kingster-single-article {
	padding-bottom: 60px;
}

.kingster-archive-taxonomy-description {
	margin-bottom: 60px;
}


/* single post format */

.kingster-blog-aside-format .kingster-single-article-content {
	font-size: 18px;
	font-style: italic;
	padding: 50px 60px 40px;
}

.kingster-blog-aside-format .kingster-excerpt-read-more {
	display: none;
}

.kingster-blog-quote-format .kingster-single-article-content {
	padding: 50px 45px 40px;
	position: relative;
}

.kingster-blog-quote-format .kingster-blog-quote-background {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background-size: cover;
	background-position: center;
	opacity: 0.3;
	filter: alpha(opacity=30);
}

.kingster-blog-quote-format .kingster-blog-quote {
	float: left;
	font-size: 120px;
	margin-top: -10px;
	line-height: 1;
	margin-right: 35px;
	position: relative;
}

.kingster-blog-quote-format .kingster-blog-content-wrap {
	overflow: hidden;
	position: relative;
}

.kingster-blog-quote-format .kingster-blog-quote-content {
	font-size: 18px;
	font-style: italic;
}

.kingster-blog-quote-format .kingster-blog-quote-author {
	font-size: 16px;
	font-style: italic;
	margin-bottom: 20px;
	font-weight: bold;
}

.kingster-blog-quote-format .kingster-blog-quote-author:before {
	content: "-";
	margin-right: 2px;
}

.kingster-blog-quote-format .kingster-excerpt-read-more {
	display: none;
}

.kingster-blog-quote-format blockquote,
.kingster-blog-quote-format q {
	background: transparent;
	border: none;
	padding: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: 18px;
}

.kingster-blog-link-format .kingster-blog-icon-link {
	font-size: 23px;
	float: left;
	margin-right: 30px;
	margin-top: 5px;
}

.kingster-blog-link-format .kingster-blog-content-wrap {
	overflow: hidden;
}

.kingster-blog-link-format .kingster-blog-title {
	font-size: 20px;
	text-transform: none;
	letter-spacing: 0px;
	margin-bottom: 9px;
}

.kingster-blog-link-format .kingster-blog-content {
	font-size: 14px;
	margin-bottom: 0px;
}

.kingster-blog-link-format .kingster-excerpt-read-more {
	display: none;
}


/* single social share */

.kingster-single-social-share {
	padding-top: 10px;
	padding-bottom: 48px;
}


/* single author */

.kingster-single-author {
	text-align: center;
	margin-bottom: 55px;
	margin-top: 41px;
	border-top-width: 1px;
	border-top-style: solid;
}

.kingster-single-author .kingster-single-author-wrap {
	max-width: 80%;
	margin: -41px auto 0px;
}

.kingster-single-author .kingster-single-author-avartar {
	max-width: 80px;
	margin: 0px auto 37px;
	overflow: hidden;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.kingster-single-author .kingster-single-author-caption {
	font-size: 14px;
	font-style: italic;
	margin-bottom: 9px;
	letter-spacing: 0.5px;
}

.kingster-single-author .kingster-single-author-title {
	font-size: 26px;
	margin-bottom: 22px;
}


/* single navigation */

.kingster-single-nav-area {
	border-top: 1px;
	border-bottom: 1px;
	border-style: solid;
	padding: 33px 0px;
	margin-bottom: 55px;
}

.kingster-single-nav i {
	font-size: 25px;
}

.kingster-single-nav .kingster-text {
	font-size: 15px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1.5px;
}

.kingster-single-nav-left {
	float: left;
}

.kingster-single-nav-left i {
	margin-right: 15px;
	vertical-align: middle;
}

.kingster-single-nav-right {
	float: right;
}

.kingster-single-nav-right i {
	margin-left: 15px;
	vertical-align: middle;
}


/* blog tags */

.kingster-single-author-tags {
	margin-bottom: 65px;
	margin-top: -18px;
}

.kingster-single-author-tags a {
	display: inline-block;
	font-size: 12px;
	padding: 5px 13px 4px;
	margin-right: 12px;
	border-width: 2px;
	border-style: solid;
	font-weight: bold;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}


/* blog style 2 */

.kingster-blog-style-2 .kingster-blog-title-wrap .kingster-single-article-title {
	margin-bottom: 0px;
	margin-top: 5px;
}

.kingster-blog-style-2 .kingster-blog-info-wrapper .kingster-blog-info .kingster-head {
	display: none;
}

.kingster-blog-style-2 .kingster-blog-info-wrapper .kingster-blog-info {
	margin-right: 0px;
	text-transform: none;
	font-size: 13px;
}

.kingster-blog-style-2 .kingster-blog-info-wrapper .kingster-blog-info .kingster-blog-info-sep {
	margin: 0px 15px;
}

.kingster-blog-style-2 .kingster-blog-info-wrapper .kingster-blog-info:first-child .kingster-blog-info-sep {
	display: none;
}

.kingster-blog-style-2 .kingster-single-author .kingster-single-author-wrap {
	margin: 0px;
}

.kingster-blog-style-2 .kingster-single-author {
	border-top-width: 0px;
	margin-top: 0px;
	text-align: left;
}

.kingster-blog-style-2 .kingster-single-author .kingster-single-author-avartar {
	margin: 0px;
	float: left;
	margin-right: 30px;
	max-width: 100px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.kingster-blog-style-2 .kingster-single-author .kingster-single-author-caption {
	display: none;
}

.kingster-blog-style-2 .kingster-single-author .kingster-single-author-content-wrap {
	overflow: hidden;
	padding-top: 12px;
}

.kingster-blog-style-2 .kingster-single-author .kingster-single-author-title {
	font-size: 20px;
	margin-bottom: 10px;
}

.kingster-blog-style-2 .gdlr-core-social-share-item {
	text-align: left;
}

.kingster-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count {
	margin-right: 25px;
	font-weight: bold;
}

.kingster-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count {
	font-size: 16px;
}

.kingster-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix {
	font-size: 16px;
	text-transform: none;
	letter-spacing: 0px;
}

.kingster-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-divider {
	display: none;
}

.kingster-blog-style-2 .kingster-single-article {
	padding-bottom: 0px;
}

.kingster-blog-style-2 .kingster-single-nav-area {
	border: none;
	padding: 30px 0px 0px;
	margin-bottom: 0px;
}

.kingster-blog-style-2 .kingster-single-nav-area a,
.kingster-blog-style-2 .kingster-single-nav-area a:hover {
	color: #fff;
}

.kingster-blog-style-2 .kingster-single-nav-area-left {
	float: left;
	width: 50%;
	padding: 50px 80px;
	position: relative;
	overflow: hidden;
}

.kingster-blog-style-2 .kingster-single-nav-area-right {
	float: left;
	width: 50%;
	padding: 50px 80px;
	position: relative;
	overflow: hidden;
}

.kingster-blog-style-2 .kingster-single-nav-area-background {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background-size: cover;
	background-position: center;
	transition: transform 300ms ease-out;
	-moz-transition: transform 300ms ease-out;
	-o-transition: transform 300ms ease-out;
	-webkit-transition: transform 300ms ease-out;
}

.kingster-blog-style-2 .kingster-single-nav-area-left:hover .kingster-single-nav-area-background,
.kingster-blog-style-2 .kingster-single-nav-area-right:hover .kingster-single-nav-area-background {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.kingster-blog-style-2 .kingster-single-nav-left i {
	font-size: 20px;
}

.kingster-blog-style-2 .kingster-single-nav-left,
.kingster-blog-style-2 .kingster-single-nav-right {
	float: none;
}

.kingster-blog-style-2 .kingster-single-nav {
	position: relative;
}

.kingster-blog-style-2 .kingster-single-nav .kingster-text {
	letter-spacing: 0px;
	text-transform: none;
}

.kingster-blog-style-2 .kingster-single-nav-title {
	font-size: 22px;
	font-weight: 800;
	display: block;
	margin-top: 2px;
}

.kingster-blog-style-2 .kingster-single-nav-area-overlay {
	opacity: 0.7;
	filter: alpha(opacity=62);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.kingster-blog-style-2 .kingster-comment-wrapper {
	padding-top: 80px;
}

.kingster-blog-style-2 .kingster-comment-content {
	margin: 0px auto;
}

.kingster-blog-style-2 .kingster-comments-area .kingster-comments-title {
	text-align: left;
	text-transform: none;
	letter-spacing: 0px;
	font-weight: 800;
	font-size: 20px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-avatar {
	max-width: 90px;
	border-radius: 3px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-author {
	font-size: 18px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-time {
	font-size: 13px;
	font-style: normal;
}

.kingster-blog-style-2 .kingster-comments-area .comment-content {
	font-size: 15px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-meta {
	margin-bottom: 16px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-reply {
	text-transform: none;
	font-weight: 800;
	letter-spacing: 0px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-article {
	padding-bottom: 0px;
	border-bottom-width: 0px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-reply-title {
	font-size: 20px;
	letter-spacing: 0px;
	text-align: left;
	text-transform: none;
	font-weight: 800;
	margin-bottom: 35px;
}

.kingster-blog-style-2 .kingster-comments-area .comment-respond {
	padding: 0px 0px 85px;
	background: transparent;
	margin-bottom: 0px;
}

.kingster-blog-style-2 .kingster-comments-area textarea {
	font-size: 14px;
	padding: 20px 22px;
	margin-bottom: 22px;
}

.kingster-blog-style-2 .kingster-comments-area input[type="text"] {
	font-size: 14px;
	padding: 20px 22px;
	margin-bottom: 22px;
}

.kingster-blog-style-2 .kingster-comments-area .kingster-comment-form-author {
	width: 33.33%;
	padding-right: 13px;
}

.kingster-blog-style-2 .kingster-comments-area .kingster-comment-form-email {
	width: 33.33%;
	padding-right: 7px;
}

.kingster-blog-style-2 .kingster-comments-area .kingster-comment-form-url {
	width: 33.33%;
	float: left;
	padding-left: 14px;
}

.kingster-blog-style-2 .kingster-comments-area .form-submit {
	text-align: left;
	padding-top: 0px;
}

.kingster-blog-style-2 .kingster-comments-area .form-submit input[type="submit"] {
	font-size: 13px;
	text-transform: none;
	padding: 16px 30px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.kingster-blog-style-2 .kingster-single-related-post-wrap {
	padding: 80px 0px 50px;
}

.kingster-blog-style-2 .kingster-single-related-post-container {
	padding-left: 80px;
	padding-right: 80px;
}

.kingster-blog-style-2 .kingster-single-related-post-title {
	font-size: 20px;
	margin-bottom: 40px;
	font-weight: 800;
}

.kingster-blog-style-2 .kingster-single-related-post-container {
	margin: 0px auto;
}

.kingster-blog-style-2 .gdlr-core-blog-grid .gdlr-core-blog-title {
	font-size: 21px;
}

.kingster-blog-style-2 .kingster-single-related-post-wrap .gdlr-core-sticky-banner {
	display: none;
}


/* blog magazine */

.kingster-blog-magazine .kingster-single-article {
	padding-bottom: 0px;
}

.kingster-blog-magazine .kingster-single-article-content .kingster-single-social-share {
	padding-bottom: 30px;
}

.kingster-blog-magazine .gdlr-core-social-share-item .gdlr-core-divider {
	display: none;
}

.kingster-blog-magazine .gdlr-core-social-share-item .gdlr-core-social-share-count {
	margin-left: 20px;
}

.kingster-blog-magazine .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count,
.kingster-blog-magazine .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix {
	font-size: 13px;
	font-weight: normal;
}

.kingster-blog-magazine .kingster-single-social-share {
	padding-top: 0px;
	padding-bottom: 23px;
}

.kingster-single-magazine-author-tags {
	padding-bottom: 23px;
}

.kingster-single-magazine-author-tags a {
	font-size: 13px;
	font-weight: 500;
	padding: 7px 12px;
	display: inline-block;
	margin-right: 5px;
}

.kingster-blog-magazine .kingster-single-nav-area {
	margin-top: 60px;
	margin-bottom: 45px;
	border: none;
	padding-top: 5px;
	padding-bottom: 5px;
	position: relative;
}

.kingster-blog-magazine .kingster-single-author {
	border: none;
	text-align: left;
	padding: 40px;
	margin-top: 50px;
}

.kingster-blog-magazine .kingster-single-author .kingster-single-author-wrap {
	margin: 0px;
}

.kingster-blog-magazine .kingster-single-author .kingster-single-author-avartar {
	float: left;
	margin-right: 30px;
}

.kingster-blog-magazine .kingster-single-author-content-wrap {
	overflow: hidden;
}

.kingster-blog-magazine .kingster-single-author .kingster-single-author-caption {
	display: none;
}

.kingster-blog-magazine .kingster-single-author .kingster-single-author-title {
	font-size: 16px;
	margin-bottom: 18px;
}

.kingster-blog-magazine .kingster-single-author-description {
	font-size: 15px;
}

.kingster-blog-magazine .kingster-single-nav-left,
.kingster-blog-magazine .kingster-single-nav-right {
	max-width: 50%;
}

.kingster-blog-magazine .kingster-single-nav-right {
	text-align: right;
}

.kingster-blog-magazine .kingster-single-nav-area-divider {
	position: absolute;
	left: 50%;
	top: 0px;
	bottom: 0px;
	border-left-width: 1px;
	border-left-style: solid;
}

.kingster-blog-magazine .kingster-single-nav .kingster-text {
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.5px;
	display: block;
	margin-bottom: 8px;
}

.kingster-blog-magazine .kingster-single-nav .kingster-single-nav-title {
	font-size: 15px;
	font-weight: bold;
}

.kingster-blog-magazine .kingster-single-related-post-wrap .kingster-single-related-post-title {
	font-size: 16px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	padding-bottom: 10px;
	margin-bottom: 35px;
}

.kingster-blog-magazine .kingster-comment-wrapper {
	margin-top: 45px;
}

.kingster-blog-magazine .kingster-single-related-post-wrap .gdlr-core-blog-grid .gdlr-core-blog-info-wrapper {
	padding-top: 0px;
}

.kingster-blog-magazine .kingster-single-related-post-wrap .gdlr-core-blog-grid .gdlr-core-blog-title {
	margin-bottom: 10px;
}

.kingster-blog-magazine .kingster-single-related-post-wrap .gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
	font-size: 11px;
}

.kingster-blog-magazine .kingster-single-related-post-wrap .gdlr-core-blog-grid-with-frame .gdlr-core-blog-grid-frame {
	padding: 20px;
}


/* comment area */

.kingster-comments-area .kingster-comments-title {
	font-size: 16px;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 2px;
	margin-bottom: 55px;
}

.kingster-comments-area ol.comment-list {
	list-style: none;
	margin-bottom: 55px;
	margin-left: 0px;
}

.kingster-comments-area ol.comment-list ol {
	margin-left: 50px;
	list-style: none;
}

.kingster-comments-area .comment-article {
	padding-bottom: 20px;
	margin-bottom: 40px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.kingster-comments-area .comment-avatar {
	max-width: 80px;
	margin-right: 35px;
	float: left;
	overflow: hidden;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.kingster-comments-area .comment-avatar img {
	display: block;
}

.kingster-comments-area .comment-meta {
	position: relative;
	margin-bottom: 20px;
}

.kingster-comments-area .comment-author {
	font-size: 19px;
	font-weight: bold;
	margin-bottom: 5px;
}

.kingster-comments-area .comment-body {
	overflow: hidden;
}

.kingster-comments-area .comment-time {
	font-size: 14px;
	font-style: italic;
}

.kingster-comments-area .comment-reply {
	font-size: 15px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1.5px;
	position: absolute;
	right: 0px;
	top: 4px;
}

.kingster-comments-area .comment-respond {
	padding: 60px 55px 40px;
	margin-bottom: 55px;
}

.kingster-comments-area ol.comment-list .comment-respond {
	margin-top: 55px;
}

.kingster-comments-area .comment-reply-title {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	text-align: center;
	margin-bottom: 40px;
}

.kingster-comments-area .comment-reply-title small {
	margin-left: 10px;
}

.kingster-comments-area textarea {
	width: 100%;
	padding: 18px 22px;
	display: block;
	border-width: 1px 1px 2px 1px;
	border-style: solid;
	margin: 0px;
	margin-bottom: 14px;
}

.kingster-comments-area input[type="text"] {
	width: 100%;
	display: block;
	border-width: 1px 1px 2px 1px;
	border-style: solid;
	padding: 16px 22px;
	margin-bottom: 14px;
}

.kingster-comments-area input[type="text"]:focus,
.kingster-comments-area textarea:focus {
	outline: none;
}

.kingster-comments-area .kingster-comment-form-author {
	width: 50%;
	padding-right: 7px;
	float: left;
}

.kingster-comments-area .kingster-comment-form-email {
	width: 50%;
	padding-left: 7px;
	float: left;
}

.kingster-comments-area .form-submit {
	text-align: center;
	padding-top: 14px;
}


/* blog title */

.kingster-blog-title-wrap {
	background-image: url('http://max-themes.net/demos/kingster/images/page-title-background.jpg');
	background-position: center;
	position: relative;
	background-size: cover;
	overflow: hidden;
}

.kingster-blog-title-wrap .kingster-blog-title-container {
	position: relative;
}

.kingster-blog-title-wrap .kingster-blog-title-overlay {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.8;
}

.kingster-blog-title-wrap.kingster-feature-image .kingster-blog-title-top-overlay {
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
	height: 413px;
	opacity: 1;
	background: url("http://max-themes.net/demos/kingster/images/blog-title-top-gradient.png") center top repeat-x;
}

.kingster-blog-title-wrap.kingster-feature-image .kingster-blog-title-bottom-overlay {
	position: absolute;
	right: 0px;
	bottom: 0px;
	left: 0px;
	height: 413px;
	opacity: 1;
	background: url("http://max-themes.net/demos/kingster/images/blog-title-gradient.png") center bottom repeat-x;
}

.kingster-blog-title-wrap.kingster-style-small .kingster-blog-title-content {
	padding-top: 93px;
	padding-bottom: 87px;
}

.kingster-blog-title-wrap.kingster-style-large .kingster-blog-title-content {
	padding-top: 184px;
	padding-bottom: 168px;
}

.kingster-blog-title-wrap .kingster-single-article-title {
	font-size: 43px;
	font-weight: 700;
	margin-bottom: 11px;
	letter-spacing: 0.5px;
}

.kingster-blog-title-wrap .kingster-single-article-head-right {
	overflow: hidden;
}

.kingster-blog-title-wrap .kingster-single-article-date-wrapper {
	margin-top: 11px;
}


/* 404 */

.kingster-not-found-wrap {
	text-align: center;
	position: relative;
}

.kingster-not-found-wrap .kingster-not-found-background {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.27;
	filter: alpha(opacity=27);
	background-position: center;
	background-size: cover;
	background-image: url('http://max-themes.net/demos/kingster/images/404-background.jpg');
}

.kingster-not-found-wrap .kingster-not-found-content {
	position: relative;
	padding: 60px 0px 65px;
}

.kingster-not-found-wrap .kingster-not-found-head {
	font-size: 150px;
	line-height: 1;
	margin-bottom: 40px;
}

.kingster-not-found-wrap .kingster-not-found-title {
	font-size: 35px;
	line-height: 1;
	font-weight: 800;
	text-transform: uppercase;
	margin-bottom: 14px;
}

.kingster-not-found-wrap .kingster-not-found-caption {
	font-size: 18px;
}

.kingster-not-found-wrap .kingster-not-found-back-to-home a {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.kingster-not-found-wrap form.search-form {
	max-width: 430px;
	margin: 58px auto 0px;
	position: relative;
	margin-bottom: 13px;
}

.kingster-not-found-wrap form.search-form input.search-field {
	font-size: 15px;
	width: 100%;
	border: none;
	padding: 22px 60px 22px 25px;
	height: 64px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.kingster-not-found-wrap form.search-form input.search-submit {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 64px;
	height: 64px;
	opacity: 0;
	filter: alpha(opacity=0);
}

.kingster-not-found-wrap form.search-form .kingster-top-search-submit {
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -15px;
	font-size: 18px;
}

body.search .kingster-not-found-wrap .kingster-not-found-head {
	font-size: 100px;
	text-transform: uppercase;
	margin-bottom: 18px;
}

body.search .kingster-not-found-wrap form.search-form {
	margin-top: 50px;
}


/* Not Found */

body.search .kingster-not-found-wrap {
	background-color: #bd584e;
}

body.search .kingster-not-found-wrap .kingster-not-found-caption {
	color: #ffe6e4;
}


/**
 * 5.0 - Widget
 */


/* sidebar */

.kingster-sidebar-area .kingster-widget {
	margin-bottom: 55px;
}

.kingster-sidebar-area .kingster-widget-title {
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 800;
	position: relative;
	margin-bottom: 28px;
	overflow: hidden;
	padding: 18px 22px;
	border-bottom-width: 3px;
	border-bottom-style: solid;
}

.kingster-sidebar-area .kingster-widget-title .gdlr-core-flexslider-nav {
	float: right;
	margin-left: 20px;
}

.kingster-sidebar-area .kingster-widget-head-text {
	display: block;
	float: left;
	margin-right: 28px;
}

.kingster-sidebar-area .kingster-widget-head-divider {
	display: block;
	border-top-width: 1px;
	border-top-style: solid;
	overflow: hidden;
	margin-top: 0.6em;
}


/* list */

.textwidget select {
	max-width: 100%;
}

.widget_categories .screen-reader-text,
.widget_archive .screen-reader-text {
	display: none;
}

.widget_rss .rss-widget-icon {
	margin-right: 10px;
	margin-top: -3px;
}

.widget_product_categories ul,
.widget_archive ul,
.widget_categories ul,
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_recent_entries ul,
.widget_rss ul {
	position: relative;
	list-style: none;
	margin-left: 0px;
}

.widget_product_categories ul li,
.widget_archive ul li,
.widget_categories ul li,
.widget_nav_menu ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_recent_entries ul li,
.widget_rss ul li {
	font-size: 14px;
	margin-bottom: 15px;
	padding-left: 30px;
	line-height: 20px;
	padding-bottom: 15px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.widget_product_categories ul ul,
.widget_archive ul ul,
.widget_categories ul ul,
.widget_nav_menu ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_recent_entries ul ul,
.widget_rss ul ul {
	margin-top: 20px;
	margin-bottom: 0px;
}

.widget_product_categories ul ul ul li,
.widget_archive ul ul ul li,
.widget_categories ul ul ul li,
.widget_nav_menu ul ul ul li,
.widget_meta ul ul ul li,
.widget_pages ul ul ul li,
.widget_recent_comments ul ul ul li,
.widget_recent_entries ul ul ul li,
.widget_rss ul ul ul li {
	padding-left: 5px;
}

.widget_product_categories ul ul ul li:before,
.widget_archive ul ul ul li:before,
.widget_categories ul ul ul li:before,
.widget_nav_menu ul ul ul li:before,
.widget_meta ul ul ul li:before,
.widget_pages ul ul ul li:before,
.widget_recent_comments ul ul ul li:before,
.widget_recent_entries ul ul ul li:before,
.widget_rss ul ul ul li:before {
	left: -25px;
}

.widget_product_categories ul ul li,
.widget_archive ul ul li,
.widget_categories ul ul li,
.widget_nav_menu ul ul li,
.widget_meta ul ul li,
.widget_pages ul ul li:last-child,
.widget_recent_comments ul ul li,
.widget_recent_entries ul ul li,
.widget_rss ul ul li {
	border-top-width: 1px;
	border-top-style: solid;
	margin-bottom: 0px;
	padding-bottom: 0px;
	border-bottom: 0px;
	padding-top: 15px;
	margin-top: 15px;
}

.widget_product_categories ul li:before,
.widget_archive ul li:before,
.widget_categories ul li:before,
.widget_nav_menu ul li:before,
.widget_meta ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_recent_entries ul li:before,
.widget_rss ul li:before {
	content: "\f178";
	font-family: FontAwesome;
	text-align: center;
	position: absolute;
	left: 0px;
	display: block;
	height: 20px;
	width: 20px;
}


/* search widget */

.widget_search form {
	display: block;
	position: relative;
}

.widget_search form:after {
	content: "\f002";
	font-family: "fontAwesome";
	font-size: 14px;
	line-height: 14px;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -7px;
}

.widget_search label .screen-reader-text {
	display: none;
}

.widget_search .search-form input.search-field {
	font-size: 13px;
	line-height: 17px;
	height: 51px;
	border-width: 2px;
	border-style: solid;
	width: 100%;
	padding: 16px 20px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.widget_search input.search-field:focus {
	outline: none;
}

.widget_search input.search-submit {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 51px;
	padding: 0px;
	opacity: 0;
	filter: alpha(opacity=0);
	z-index: 1;
}


/* tag cloud */

.tagcloud {
	zoom: 1;
}

.tagcloud:after {
	content: " ";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.tagcloud a {
	font-size: 11px !important;
	text-transform: uppercase;
	font-weight: 600;
	float: left;
	padding: 7px 16px 8px;
	border-width: 1px;
	border-style: solid;
	margin-right: 10px;
	margin-bottom: 10px;
}


/* calendar */

.widget_calendar table {
	width: 100%;
	margin-bottom: 0px;
}

.widget_calendar table thead th {
	font-size: 13px;
	font-weight: bold;
	padding: 10px 0px;
}

.widget_calendar table tbody td {
	text-align: center;
	padding: 10px 0px;
}

.widget_calendar table tfoot td {
	text-align: center;
	padding: 10px 0px;
}

.widget_calendar caption {
	font-size: 14px;
	margin-bottom: 20px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
}


/* table */

table {
	width: 100%;
	text-align: center;
	border-spacing: 2px;
	border-collapse: separate;
	margin-bottom: 20px;
}

table tr td {
	padding: 15px 0px;
}

table tr th {
	padding: 13px 0px;
	font-size: 16px;
	font-weight: normal;
}


/* for goodlayer core plugin */

body.kingster-boxed .gdlr-core-column-extend-left .gdlr-core-pbf-background-wrap {
	margin-left: -50px;
}

body.kingster-boxed .gdlr-core-column-extend-right .gdlr-core-pbf-background-wrap {
	margin-right: -50px;
}


/**
 * 6.0 - Responsive
 */

.kingster-mobile-header-wrap {
	display: none;
}

.kingster-mobile-header {
	padding-top: 15px;
	padding-bottom: 15px;
	z-index: 99;
	position: relative;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.kingster-mobile-header.kingster-fixed-navigation {
	display: none;
}

.kingster-mobile-header .kingster-mobile-header-container {
	position: relative;
}

.kingster-mobile-header .kingster-logo {
	padding: 0px 15px;
}

.kingster-mobile-header .kingster-logo-inner {
	line-height: 45px;
	margin-right: 80px;
}

.kingster-mobile-header .kingster-logo-inner img {
	vertical-align: middle;
	width: auto;
}


/*--- tablet ---*/

@media only screen and (max-width: 1140px) {
	html .widget_product_search input[type="search"]#woocommerce-product-search-field {
		width: 100%;
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 1260px) {
	body {
		width: 100%;
		overflow-x: hidden;
	}
	body.kingster-boxed div.kingster-body-wrapper {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.kingster-bullet-anchor {
		display: none;
	}
	/* remove header */
	.kingster-top-bar {
		display: none;
	}
	.kingster-header-wrap.kingster-header-style-plain,
	.kingster-header-wrap.kingster-header-style-bar,
	.kingster-navigation-bar-wrap,
	.kingster-header-background-transparent,
	.kingster-header-boxed-wrap,
	.kingster-header-side-nav,
	.kingster-header-transparent-substitute,
	.kingster-sticky-navigation {
		display: none !important;
	}
	.kingster-mobile-header-wrap {
		display: block;
	}
	.kingster-mobile-header-wrap .kingster-top-bar {
		display: block;
	}
	.kingster-mobile-header-wrap .kingster-top-bar {
		font-size: 13px;
	}
	.kingster-mobile-header-wrap .kingster-top-bar-left,
	.kingster-mobile-header-wrap .kingster-top-bar-right {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.kingster-mobile-header.kingster-fixed-navigation {
		display: block;
	}
	.kingster-sticky-menu-placeholder {
		display: none !important;
	}
	body .kingster-header-side-content.kingster-style-left {
		margin-left: 0px;
	}
	body .kingster-header-side-content.kingster-style-right {
		margin-right: 0px;
	}
	body.single-product.woocommerce div.product form.cart {
		float: none;
		margin-bottom: 30px;
		width: auto;
		margin-right: 0px;
	}
	body.single-product.woocommerce div.product .product_meta {
		margin-top: 0px;
	}
	.kingster-blog-style-2 .kingster-single-nav-area-left,
	.kingster-blog-style-2 .kingster-single-nav-area-right {
		padding: 30px 40px;
	}
	.kingster-blog-style-2 .kingster-single-related-post-container {
		padding-left: 30px;
		padding-right: 30px;
	}
}


/*--- mobile landscape style ---*/

@media only screen and (max-width: 767px) {
	body.kingster-body-front .kingster-container {
		max-width: 500px;
		padding-left: 15px;
		padding-right: 15px;
	}
	body.kingster-body-front .gdlr-core-container {
		max-width: 500px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.kingster-column-10,
	.kingster-column-12,
	.kingster-column-15,
	.kingster-column-20,
	.kingster-column-24,
	.kingster-column-30,
	.kingster-column-36,
	.kingster-column-40,
	.kingster-column-45,
	.kingster-column-48,
	.kingster-column-50,
	.kingster-column-60 {
		width: 100%;
	}
	/* header */
	.kingster-mobile-header-wrap .kingster-top-bar-left,
	.kingster-mobile-header-wrap .kingster-top-bar-right {
		float: none;
		text-align: center;
	}
	.kingster-top-bar-right-text {
		display: block;
		margin-left: 0px;
	}
	.kingster-top-bar-right-social {
		margin-left: 0px;
	}
	.kingster-top-bar-right-social a:first-child {
		margin-left: 0px;
	}
	.kingster-top-bar .kingster-top-bar-left-menu {
		margin-right: 0px;
	}
	.kingster-top-bar .kingster-top-bar-right-menu {
		margin-left: 0px;
	}
	.kingster-page-title-wrap .kingster-page-title-content {
		padding-top: 60px !important;
		padding-bottom: 50px !important;
	}
	.kingster-blog-title-wrap .kingster-blog-title-content {
		padding-top: 60px !important;
		padding-bottom: 50px !important;
	}
	.kingster-single-article-date-wrapper {
		display: none;
	}
	.kingster-overlay-menu-content ul.menu>li {
		font-size: 18px;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
	.kingster-overlay-menu-content ul.menu {
		padding-top: 120px;
		padding-bottom: 100px;
		max-width: 80%;
	}
	.kingster-overlay-menu-content .kingster-overlay-menu-close {
		top: 35px;
	}
	body.admin-bar .kingster-overlay-menu-content {
		margin-top: 0px;
	}
	body.admin-bar .kingster-overlay-menu-content ul.menu {
		padding-top: 160px;
	}
	body.admin-bar .kingster-overlay-menu-content .kingster-overlay-menu-close {
		top: 80px;
	}
	/* single*/
	.kingster-comments-area .comment-respond {
		padding: 45px 30px;
	}
	.kingster-comments-area .kingster-comment-form-author {
		width: 100%;
		padding-right: 0;
	}
	.kingster-comments-area .kingster-comment-form-email {
		width: 100%;
		padding-left: 0;
	}
	.kingster-comments-area .comment-avatar {
		float: none;
		margin-right: 0px;
		margin-bottom: 30px;
	}
	.kingster-comments-area ol.comment-list ol {
		margin-left: 10px;
	}
	.kingster-blog-style-2 .kingster-comments-area .kingster-comment-form-author {
		width: 100%;
		padding-right: 0px;
	}
	.kingster-blog-style-2 .kingster-comments-area .kingster-comment-form-email {
		width: 100%;
		padding-right: 0px;
	}
	.kingster-blog-style-2 .kingster-comments-area .kingster-comment-form-url {
		width: 100%;
		padding-left: 0px;
	}
	.kingster-blog-style-2 .kingster-single-nav-area {
		padding-top: 0px;
	}
	.kingster-blog-style-2 .kingster-single-nav-area-left,
	.kingster-blog-style-2 .kingster-single-nav-area-right {
		float: none;
		width: 100%;
		display: block;
		text-align: center;
	}
	.kingster-blog-style-2 .kingster-single-nav-area a i {
		display: none;
	}
	.kingster-blog-style-2 .kingster-single-related-post-container {
		max-width: 500px;
	}
	/* footer */
	.kingster-footer-wrapper {
		padding-bottom: 7px;
	}
	.kingster-footer-column {
		margin-bottom: 60px;
	}
	.kingster-footer-wrapper.kingster-with-column-divider .kingster-footer-column {
		padding: 0px 15px;
		margin-bottom: 60px;
		border: none;
	}
	.kingster-footer-wrapper.kingster-with-column-divider .kingster-footer-column:last-child {
		margin-bottom: 20px;
	}
	.kingster-fixed-footer-placeholder {
		display: none;
	}
	.kingster-fixed-footer {
		position: static;
	}
	.kingster-copyright-left,
	.kingster-copyright-right {
		float: none;
	}
	body .woocommerce .col2-set,
	.woocommerce-page .col2-set {
		width: 100%;
		float: none;
		margin-right: 0%;
	}
	body div#order_review {
		width: 100%;
	}
}


/*--- mobile portrait style ---*/

@media only screen and (max-width: 419px) {
	body {
		min-width: 320px;
	}
	.kingster-top-search-wrap input.search-field {
		font-size: 30px;
		padding-right: 90px;
	}
	.kingster-top-search-wrap .kingster-top-search-submit {
		right: 48px;
		font-size: 24px;
		margin-top: -2px;
	}
	.kingster-top-search-wrap input.search-submit {
		right: 48px;
	}
	.kingster-top-search-wrap .kingster-top-search-close {
		font-size: 40px;
	}
	.kingster-top-search-wrap input.search-field {
		padding-bottom: 10px;
	}
}


/**
 * 7.0 - Plugins
 */


/*-------------------*/


/*--- wpml        ---*/


/*-------------------*/

ul.sf-menu>.menu-item-language img.iclflag {
	vertical-align: baseline;
	margin-right: 10px;
}

ul.sf-menu>.menu-item-language ul.submenu-languages {
	display: none;
	position: absolute;
	z-index: 99;
	list-style: none;
	margin: 0px 0px 0px -15px;
}

ul.sf-menu>.menu-item-language li {
	padding: 1px 11px;
}

ul.sf-menu>.menu-item-language li a {
	padding: 6px 12px;
	display: block;
}

ul.sf-menu>.menu-item-language li:first-child {
	padding-top: 12px;
}

ul.sf-menu>.menu-item-language li:last-child {
	padding-bottom: 12px;
}

.kingster-custom-wpml-flag {
	margin-right: 26px;
}

.kingster-custom-wpml-flag .kingster-custom-wpml-flag-item {
	margin-left: 20px;
}

.kingster-custom-wpml-flag .kingster-custom-wpml-flag-item:first-child {
	margin-left: 0px;
}


/*-------------------*/


/*--- wpcf7       ---*/


/*-------------------*/

.kingster-body span.wpcf7-not-valid-tip {
	font-size: 13px;
	margin-bottom: 15px;
}

.kingster-body div.wpcf7-response-output {
	font-size: 13px;
	padding: 6px 20px;
	clear: both;
	margin: 0px;
}

.wpcf7 input,
.wpcf7 select {
	font-size: 15px;
	padding: 15px 22px;
	width: 100%;
	display: block;
}

.wpcf7 select {
	-webkit-appearance: none;
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
}

.wpcf7 input[type="button"],
.wpcf7 input[type="submit"],
input[type="reset"] {
	width: auto;
}

.wpcf7 input[type="checkbox"],
.wpcf7 input[type="radio"] {
	width: auto;
	display: inline;
}

.wpcf7 .wpcf7-form-control .wpcf7-list-item {
	display: block;
	margin-bottom: 5px;
}

.wpcf7 textarea {
	font-size: 15px;
	padding: 18px 22px;
	height: 120px;
	width: 100%;
}


/*-------------------*/


/*--- woocommerce ---*/


/*-------------------*/


/* style */

figure.woocommerce-product-gallery__wrapper {
	max-width: none;
}

body.woocommerce .woocommerce-error,
body.woocommerce .woocommerce-info,
body.woocommerce .woocommerce-message {
	padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
	padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-message:before {
	top: 0.7em;
}

.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button {
	margin-top: 5px;
	padding: 0;
	background-color: transparent;
}

.woocommerce .woocommerce-error .button:hover,
.woocommerce .woocommerce-info .button:hover,
.woocommerce .woocommerce-message .button:hover {
	background-color: transparent;
}

.woocommerce div.product form.cart .variations td.label {
	padding-right: 1.3em;
	padding-left: 1.3em;
}

.woocommerce div.product form.cart .variations td.value,
.woocommerce div.product form.cart .variations th.value {
	min-width: 210px;
}

.woocommerce div.product form.cart .variations select {
	float: left;
	margin-top: 0.6em;
	margin-left: 2em;
}

a.reset_variations {
	margin-right: 15px;
	margin-left: 7px;
}

.woocommerce div.product form.cart .variations select {
	min-width: 59%;
}

.related.products h2 {
	font-size: 23px;
	margin-bottom: 42px;
}

.woocommerce table.shop_attributes th {
	background: transparent;
	padding-top: 16px;
	padding-bottom: 16px;
}

.woocommerce table.shop_attributes td,
.woocommerce table.shop_attributes th {
	border-bottom: 0;
}

.woocommerce table.shop_attributes {
	border-top: 0;
}

.single-product.woocommerce #review_form #respond p.comment-form-comment {
	margin-bottom: 25px;
}

.woocommerce table.shop_table {
	border: 0;
}

.woocommerce table.shop_table.shop_table_responsive.cart {
	border-spacing: 0;
}

.woocommerce table.shop_table th {
	padding: 17px 12px;
	background: none;
}

.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(odd),
.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(even) {
	background-color: transparent;
}

.woocommerce table.shop_table td {
	padding: 19px 20px;
}

.woocommerce-cart table.cart img {
	width: 70px;
}

.woocommerce td.product-name dl.variation dd,
.woocommerce td.product-name dl.variation dt {
	margin-bottom: 0;
}

.woocommerce a.remove {
	width: auto;
}

.cart_item input.input-text.qty.text {
	padding: 15px 0px 15px 10px;
	border-bottom-width: 1px;
}

.woocommerce-cart table.cart td.actions .coupon .input-text {
	padding: 15px 23px 15px;
	height: 49px;
}

.woocommerce .cart input.button[name="apply_coupon"] {
	height: 49px;
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
	width: 180px;
}

.woocommerce .cart .button,
.woocommerce .cart input.button {
	padding: 19px 20px 18px;
	border-radius: 0;
	font-size: 12px;
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
	padding: 15px 20px;
	border-radius: 0;
	font-size: 12px;
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
	padding: inherit;
	opacity: inherit;
	padding-left: 23px;
	padding-right: 23px;
}

.woocommerce .cart_totals h2 {
	font-size: 18px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th {
	border-top: 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table {
	border-spacing: 0;
	margin: 0;
}

.woocommerce-cart .wc-proceed-to-checkout {
	padding: 0;
}

.woocommerce table.shop_table tbody:first-child tr:first-child td,
.woocommerce table.shop_table tbody:first-child tr:first-child th {
	padding-left: 20px;
	padding-right: 20px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th {
	padding-left: 20px;
	padding-right: 20px;
}

.woocommerce a.showcoupon {
	margin-left: 20px;
	color: #baf2ff;
}

.woocommerce .woocommerce-billing-fields h3,
.woocommerce .woocommerce-shipping-fields h3,
#order_review_heading {
	font-size: 17px;
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
	padding: 13px 15px;
	border-bottom-width: 1px;
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
	margin-bottom: 35px;
}

.woocommerce .col2-set,
.woocommerce-page .col2-set {
	width: 47%;
	float: left;
	margin-right: 3%;
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
	width: 100%;
}

.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
	width: 100%;
}

div#order_review {
	float: left;
	width: 50%;
}

.woocommerce form .form-row {
	margin: 0 0 14px;
}

.woocommerce form p.form-row.form-row-first {
	margin: 0;
}

.select2-container .select2-choice {
	padding: 6px 1px 6px 13px;
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
	width: 49%;
}

.shop_table.woocommerce-checkout-review-order-table {
	border-spacing: 0px;
}

.woocommerce table.shop_table th {
	padding: 17px 20px 17px 20px;
}

.woocommerce .cart_item td.product-total {
	padding-right: 20px;
	padding-left: 20px;
}

.woocommerce .cart_item td.product-name {
	padding-left: 20px;
}

.woocommerce table.shop_table td {
	padding-right: 20px;
}

.checkout_coupon .form-row.form-row-last {
	margin-bottom: 0;
}

table tr.cart_item {
	background: transparent;
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
	font-size: 13px;
}

html .woocommerce a.remove {
	border-radius: 100%;
	color: #333 !important;
}

.woocommerce-checkout table tr.cart_item {
	background: transparent;
}

li.woocommerce-MyAccount-navigation-link {
	padding: 13px 0px;
	padding-bottom: 13px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
	width: 28%;
}

li.woocommerce-MyAccount-navigation-link.is-active {
	font-weight: 600;
}

header.woocommerce-Address-title.title h3 {
	font-size: 18px;
}

.woocommerce-Address address {
	padding: 25px;
}

.u-columns.woocommerce-Addresses.col2-set.addresses {
	width: 100%;
	margin-bottom: 40px;
}

.woocommerce-account .woocommerce-MyAccount-content {
	padding: 31px;
}

.woocommerce-Address address,
.woocommerce-account .woocommerce-MyAccount-content {
	background: #f5f5f5;
}


/*-- Widget --*/

.woocommerce ul.cart_list li dl dd,
.woocommerce ul.cart_list li dl dt,
.woocommerce ul.product_list_widget li dl dd,
.woocommerce ul.product_list_widget li dl dt {
	margin-bottom: 0em;
}

.woocommerce ul.cart_list li dl,
.woocommerce ul.product_list_widget li dl {
	padding-left: 0;
	border-left: 0;
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
	margin-bottom: 20px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	padding-bottom: 15px;
}

.woocommerce .widget_shopping_cart .cart_list li:last-child,
.woocommerce.widget_shopping_cart .cart_list li:last-child {
	margin-bottom: 15px;
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
	border-top: 0;
	font-size: 17px;
}

.widget_shopping_cart_content .total span.woocommerce-Price-amount.amount {
	margin-left: 5px;
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
	width: 47px;
}

.widget_shopping_cart a.button.wc-forward {
	padding: 19px 28px;
	font-size: 14px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.woocommerce .widget_price_filter .price_slider_amount .button {
	float: left;
	font-size: 14px;
	padding: 16px 26px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.price_slider_amount .price_label {
	margin-top: 24px;
	font-size: 17px;
	font-weight: 700;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
	padding: 15px 0;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
	padding: 15px 0;
	margin: 0;
	list-style: none;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

html ul.cart_list li a,
html .woocommerce ul.product_list_widget li a {
	font-weight: 400;
}

html .woocommerce ul.cart_list li a,
html .woocommerce ul.product_list_widget li a {
	font-weight: 400;
}

html .woocommerce ul.cart_list li .star-rating {
	float: left;
	margin-top: 10px;
}

html .woocommerce ul.product_list_widget li .star-rating {
	float: left;
	margin-top: 3px;
	margin-right: 10px;
}

html .woocommerce ul.product_list_widget li del {
	margin-right: 10px;
}

html .product_list_widget span.reviewer {
	margin-top: 4px;
	margin-left: 12px;
	float: left;
}

html .widget_product_search label.screen-reader-text {
	display: none;
}

html .widget_product_search input[type="submit"] {
	height: 47px;
}

html .widget_product_search input#woocommerce-product-search-field {
	height: 47px;
	padding: 14px 12px;
	border-bottom-width: 1px;
	width: 62%;
	font-size: 13px;
}

.widget.woocommerce ul.cart_list li:first-child,
.widget.woocommerce ul.product_list_widget li:first-child {
	padding-top: 0px;
}


/*-- Fixed Color --*/

.woocommerce .woocommerce-message:before {
	color: #fff;
}

.woocommerce .woocommerce-error {
	background-color: #bd584e;
	border-top: 3px solid #bd584e;
	color: #fff;
}

.woocommerce .woocommerce-info {
	background-color: #46a5ca;
	border-top: 3px solid #46a5ca;
	color: #fff;
}

.woocommerce .woocommerce-message {
	background-color: #46ca7b;
	border-top: 3px solid #46ca7b;
	color: #fff;
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
	color: #ffffff;
}

.single-product.woocommerce div.product .quantity .qty {
	background: #f3f3f3;
}

.woocommerce .woocommerce-info:before {
	color: #fff
}

.woocommerce .woocommerce-error:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-message:before {
	color: #fff !important;
	top: inherit;
}

html .woocommerce a.remove:hover {
	color: #afafaf !important;
	background: transparent !important;
}

html .woocommerce form .form-row .required {
	color: #c5c5c5;
}

html .woocommerce form .form-row.woocommerce-invalid .select2-container,
html .woocommerce form .form-row.woocommerce-invalid input.input-text,
html .woocommerce form .form-row.woocommerce-invalid select {
	border-color: #d4d4d4;
}

html .woocommerce form .form-row.woocommerce-invalid label {
	color: #9e9e9e;
}

html .woocommerce form .form-row.woocommerce-validated .select2-container,
html .woocommerce form .form-row.woocommerce-validated input.input-text,
html .woocommerce form .form-row.woocommerce-validated select {
	border-color: #d4d4d4;
}


/* single product */

html .woocommerce span.onsale {
	font-size: 13px;
	font-weight: 600;
	line-height: 48px;
	width: 48px;
	padding: 2px 0px 0px;
	height: 48px;
}

.single-product.woocommerce .kingster-page-wrapper {
	border-top-width: 1px;
	border-top-style: solid;
}

.single-product.woocommerce .woocommerce-breadcrumb {
	font-size: 14px;
}

.single-product.woocommerce .product_title {
	font-size: 40px;
	margin-bottom: 2px;
}

.single-product.woocommerce div.product .woocommerce-product-rating {
	margin-bottom: 2px;
}

.single-product.woocommerce div.product .star-rating {
	font-size: 14px;
	padding-right: 15px;
	box-sizing: content-box;
	margin-right: 10px;
}

.single-product.woocommerce div.product .star-rating:before,
.single-product.woocommerce div.product .star-rating span:before {
	letter-spacing: 3px;
}

.single-product.woocommerce div.product .woocommerce-review-link {
	font-size: 13px;
}

.single-product.woocommerce div.product p.price {
	padding-top: 27px;
	margin-bottom: 28px;
}

.single-product.woocommerce div.product p.price ins {
	text-decoration: none;
}

.single-product.woocommerce div.product p.price .woocommerce-Price-amount {
	font-size: 26px;
	font-weight: bold;
}

.single-product.woocommerce div.product p.price del .woocommerce-Price-amount {
	font-size: 23px;
	margin-right: 9px;
}

.single-product.woocommerce div.product span.onsale {
	position: static;
	display: inline-block;
	margin-right: 15px;
	vertical-align: middle;
}

.single-product.woocommerce div.product .quantity .qty {
	height: 60px;
	font-size: 16px;
	width: 75px;
	padding-left: 15px;
	border: none;
}

.single-product.woocommerce div.product form.cart {
	margin: 25px 30px 55px 0px;
	float: left;
}

.single-product.woocommerce div.product form.cart.variations_form {
	width: 300px;
}

.single-product.woocommerce div.product form.cart .button {
	font-size: 13px;
	height: 60px;
	text-transform: uppercase;
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	padding: 10px 30px;
}

.single-product.woocommerce div.product .product_meta {
	margin-top: 50px;
}

.single-product.woocommerce div.product .product_meta {
	overflow: hidden;
	padding-top: 5px;
}

.single-product.woocommerce div.product .product_meta>span {
	display: block;
	font-size: 13px;
	margin-bottom: 5px;
}

.single-product.woocommerce div.product .product_meta>span .kingster-head {
	font-weight: 600;
}

.single-product.woocommerce div.product .woocommerce-variation-add-to-cart-disabled {
	display: none;
}

.single-product.woocommerce div.product .kingster-woocommerce-social-share {
	clear: both;
	padding-top: 15px;
	margin-top: 50px;
	border-top-width: 2px;
	border-top-style: solid;
}

.single-product.woocommerce div.product .gdlr-core-social-share-item a {
	font-size: 14px;
}

.single-product.woocommerce div.product .kingster-woocommerce-tab {
	clear: both;
	padding-top: 60px;
	margin-bottom: 50px;
}

.single-product.woocommerce div.product .kingster-woocommerce-tab .gdlr-core-tab-item-title {
	font-size: 16px;
	text-transform: none;
	letter-spacing: 0px;
	font-weight: 600;
	padding-bottom: 18px;
}

.single-product.woocommerce div.product .kingster-woocommerce-tab .gdlr-core-tab-item-title-line {
	border-bottom-width: 1px;
	bottom: -1px;
}

.single-product.woocommerce div.product .kingster-woocommerce-tab .gdlr-core-tab-item-title-wrap {
	border-bottom-width: 1px;
}

.single-product.woocommerce div.product .kingster-woocommerce-tab .gdlr-core-tab-item-content {
	max-width: 900px;
	margin: 0px auto;
}

.single-product.woocommerce .woocommerce-Reviews-title {
	display: none;
}

.single-product.woocommerce #reviews {
	padding-top: 43px;
}

.single-product.woocommerce #reviews .comment-respond {
	text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li img.avatar {
	border: none;
	width: 85px;
	position: static;
	padding: 0px;
	border-radius: 50%;
	margin-right: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text {
	overflow: hidden;
	margin-left: 0px;
	padding: 0;
	border: none;
	text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li {
	padding: 39px 49px;
	margin-bottom: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
	margin-bottom: 18px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong[itemprop="author"] {
	display: block;
	font-size: 17px;
	text-transform: capitalize;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta time[itemprop="datePublished"] {
	display: block;
	font-size: 15px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
	float: none;
	margin-top: 24px;
}

.single-product.woocommerce #reviews #review_form_wrapper {
	padding-top: 35px;
}

.single-product.woocommerce #reviews .comment-reply-title {
	font-size: 22px;
	margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label {
	font-size: 15px;
	font-weight: 600;
}

.single-product.woocommerce #review_form #respond p {
	margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label[for="rating"] {
	display: inline-block;
	margin-right: 30px;
	vertical-align: middle;
}

.single-product.woocommerce #review_form #respond p.stars {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 0px;
	font-size: 19px;
	line-height: 1;
}

.single-product.woocommerce #review_form #respond p.stars a {
	margin-right: 5px;
}

.single-product.woocommerce #review_form #respond input[type="text"],
.single-product.woocommerce #review_form #respond input[type="email"] {
	display: block;
	margin-top: 20px;
	border-bottom-width: 1px;
	width: 100%;
	height: 55px;
}

.single-product.woocommerce #review_form #respond textarea {
	display: block;
	margin-top: 20px;
	border-bottom-width: 1px;
	width: 100%;
	height: 150px;
}

.single-product.woocommerce #review_form #respond .form-submit input {
	font-size: 13px;
	height: 58px;
	text-transform: uppercase;
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	padding: 10px 38px;
}

.woocommerce.single-product .related.products {
	clear: left;
}


/*-------------*/


/*--- mmenu ---*/


/*-------------*/

.mm-menu,
.mm-panels,
.mm-panels>.mm-panel {
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
	box-sizing: border-box
}

.mm-btn,
.mm-menu {
	box-sizing: border-box
}

.mm-listview a,
.mm-listview a:hover,
.mm-navbar a,
.mm-navbar a:hover {
	text-decoration: none
}

.mm-hidden {
	display: none!important
}

.mm-wrapper {
	overflow-x: hidden;
	position: relative
}

.mm-menu {
	display: block;
	padding: 0;
	position: absolute
}

.mm-panels,
.mm-panels>.mm-panel {
	background: inherit;
	border-color: inherit;
	position: absolute
}

.mm-btn,
.mm-panel.mm-highest {
	z-index: 1
}

.mm-panels {
	overflow: hidden
}

.mm-panel {
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
}

.mm-panel.mm-opened {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.mm-panel.mm-subopened {
	-webkit-transform: translate3d(-30%, 0, 0);
	transform: translate3d(-30%, 0, 0)
}

.mm-panels>.mm-panel {
	-webkit-overflow-scrolling: touch;
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 20px
}

.mm-listview .mm-divider,
.mm-listview>li>a,
.mm-listview>li>span,
.mm-navbar .mm-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden
}

.mm-panels>.mm-panel.mm-hasnavbar {
	padding-top: 40px
}

.mm-vertical .mm-panel {
	-webkit-transform: none!important;
	transform: none!important
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
	display: none;
	padding: 10px 0 10px 10px
}

.mm-listview .mm-vertical .mm-panel .mm-listview>li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview>li:last-child:after {
	border-color: transparent
}

.mm-vertical li.mm-opened>.mm-panel,
li.mm-vertical.mm-opened>.mm-panel {
	display: block
}

.mm-listview>li.mm-vertical>.mm-next,
.mm-vertical .mm-listview>li>.mm-next {
	height: 40px;
	bottom: auto
}

.mm-listview>li.mm-vertical>.mm-next:after,
.mm-vertical .mm-listview>li>.mm-next:after {
	top: 16px;
	bottom: auto
}

.mm-listview>li.mm-vertical.mm-opened>.mm-next:after,
.mm-vertical .mm-listview>li.mm-opened>.mm-next:after {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	right: 19px
}

.mm-btn {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
	content: '';
	border: 2px solid transparent;
	display: block;
	width: 5px;
	height: 5px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.mm-clear:before,
.mm-close:before {
	border-right: none;
	border-bottom: none;
	right: 18px
}

.mm-clear:after,
.mm-close:after {
	border-left: none;
	border-top: none;
	right: 25px
}

.mm-arrow:after,
.mm-next:after {
	content: '';
	border: 1px solid transparent;
	display: block;
	width: 6px;
	height: 6px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.mm-prev:before {
	border-right: none;
	border-bottom: none;
	left: 0px
}

.mm-arrow:after,
.mm-next:after {
	border-top: none;
	border-left: none;
	right: 2px
}

.mm-navbar {
	text-align: center;
	line-height: 20px;
	height: 40px;
	margin: 0;
}

.mm-navbar .mm-btn:last-child {
	text-align: right;
	padding-right: 20px;
	right: 0
}

.mm-panel .mm-navbar {
	display: none
}

.mm-panel.mm-hasnavbar .mm-navbar {
	display: block;
	margin: 0px 25px 30px;
	position: relative;
}

.mm-listview,
.mm-listview>li {
	list-style: none;
	display: block;
	padding: 0;
	margin: 0
}

.mm-listview {
	font: inherit;
	font-size: 14px;
	line-height: 20px
}

.mm-listview>li {
	position: relative
}

.mm-listview>li,
.mm-listview>li .mm-next,
.mm-listview>li .mm-next:before,
.mm-listview>li:after {
	border-color: inherit
}

.mm-listview>li>a,
.mm-listview>li>span {
	color: inherit;
	display: block;
	margin: 0
}

.mm-listview>li>a.mm-arrow,
.mm-listview>li>span.mm-arrow {
	padding-right: 50px
}

.mm-listview .mm-next {
	background: rgba(3, 2, 1, 0);
	width: 50px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2
}

.mm-listview .mm-next.mm-fullsubopen {
	width: 100%
}

.mm-listview .mm-next.mm-fullsubopen:before {
	border-left: none
}

.mm-listview .mm-next.mm-fullsubopen+a,
.mm-listview .mm-next.mm-fullsubopen+span {
	padding-right: 50px;
	margin-right: 0
}

.mm-listview .mm-inset {
	list-style: disc inside;
	padding: 0 10px 15px 40px;
	margin: 0
}

.mm-listview .mm-inset>li {
	padding: 5px 0
}

.mm-listview .mm-divider {
	font-size: 10px;
	text-transform: uppercase;
	text-indent: 20px;
	line-height: 25px
}

.mm-listview .mm-spacer {
	padding-top: 40px
}

.mm-listview .mm-spacer>.mm-next {
	top: 40px
}

.mm-listview .mm-spacer.mm-divider {
	padding-top: 25px
}

.mm-page {
	box-sizing: border-box;
	position: relative
}

.mm-slideout {
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	z-index: 2
}

html.mm-opened {
	overflow-x: hidden;
	position: static;
}

#mm-blocker {
	background: rgba(3, 2, 1, 0);
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100
}

html.mm-blocking #mm-blocker {
	display: block
}

.mm-menu.mm-offcanvas {
	z-index: 100;
	display: none;
	position: fixed
}

.mm-menu.mm-offcanvas.mm-current {
	display: block
}

.mm-menu {
	width: 350px
}

@media all and (max-width:550px) {
	.mm-menu {
		width: 250px
	}
}

html.mm-opening .mm-slideout {
	-webkit-transform: translate3d(350px, 0, 0);
	transform: translate3d(350px, 0, 0)
}

@media all and (max-width:550px) {
	html.mm-opening .mm-slideout {
		-webkit-transform: translate3d(250px, 0, 0);
		transform: translate3d(250px, 0, 0)
	}
}


/* right effect */

html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
	-webkit-transform: scale(.7, .7) translate3d(30%, 0, 0);
	transform: scale(.7, .7) translate3d(30%, 0, 0);
	-webkit-transform-origin: right center;
	transform-origin: right center
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
	-webkit-transform: scale(1, 1) translate3d(0, 0, 0);
	transform: scale(1, 1) translate3d(0, 0, 0)
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
	-webkit-transform: translate3d(30%, 0, 0);
	transform: translate3d(30%, 0, 0)
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
}

.mm-menu.mm-pageshadow.mm-right:after {
	left: auto;
	right: 100%
}

.mm-menu.mm-right {
	left: auto;
	right: 0
}

html.mm-right.mm-opening .mm-slideout {
	-webkit-transform: translate3d(-350px, 0, 0);
	transform: translate3d(-350px, 0, 0)
}

@media all and (max-width:550px) {
	html.mm-right.mm-opening .mm-slideout {
		-webkit-transform: translate3d(-250px, 0, 0);
		transform: translate3d(-250px, 0, 0)
	}
}

.mm-menu.mm-front.mm-right,
.mm-menu.mm-next.mm-right {
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
}


/* page dim */

html.mm-pagedim #mm-blocker,
html.mm-pagedim-black #mm-blocker,
html.mm-pagedim-white #mm-blocker {
	opacity: 0
}

html.mm-pagedim-black.mm-opening #mm-blocker,
html.mm-pagedim-white.mm-opening #mm-blocker,
html.mm-pagedim.mm-opening #mm-blocker {
	opacity: .3;
	transition: ease .4s
}

html.mm-pagedim #mm-blocker {
	background: inherit
}

html.mm-pagedim-white #mm-blocker {
	background: #fff
}

html.mm-pagedim-black #mm-blocker {
	background: #000
}


/* custom */

ul.mm-listview li {
	margin: 0px 25px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

ul.mm-listview li a,
ul.mm-listview li span.kingster-mm-menu-blank {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.5px;
	padding: 17px 0px;
}

.mm-navbar .mm-btn {
	line-height: 40px;
	font-size: 30px;
	display: block;
	text-align: left;
	left: -8px;
	padding: 0px;
}

.mm-navbar .mm-btn:before {
	content: "\34";
	font-family: elegantIcons;
}

.mm-navbar .mmenu-custom-close {
	line-height: 40px;
	font-size: 30px;
	display: block;
	text-align: left;
	margin-left: -8px;
	cursor: pointer;
}

.mm-navbar .mmenu-custom-close:after {
	content: "\4d";
	font-family: elegantIcons;
}

.mm-navbar .mm-title {
	display: none;
}

.mm-navbar .mm-title:first-child {
	display: block;
}

html.kingster-mmenu-left .kingster-sticky-navigation.kingster-fixed-navigation,
html.kingster-mmenu-right .kingster-sticky-navigation.kingster-fixed-navigation {
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
}

html.mm-opening.kingster-mmenu-left .kingster-sticky-navigation.kingster-fixed-navigation {
	-webkit-transform: translate3d(350px, 0, 0);
	transform: translate3d(350px, 0, 0);
}

html.mm-opening.kingster-mmenu-right .kingster-sticky-navigation.kingster-fixed-navigation {
	-webkit-transform: translate3d(-350px, 0, 0);
	transform: translate3d(-350px, 0, 0);
}

html.kingster-mmenu-left .kingster-mobile-header-wrap .kingster-top-bar,
html.kingster-mmenu-left .kingster-mobile-header,
html.kingster-mmenu-right .kingster-mobile-header-wrap .kingster-top-bar,
html.kingster-mmenu-right .kingster-mobile-header {
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
}

html.mm-opening.kingster-mmenu-left .kingster-mobile-header-wrap .kingster-top-bar,
html.mm-opening.kingster-mmenu-left .kingster-mobile-header {
	-webkit-transform: translate3d(350px, 0, 0);
	transform: translate3d(350px, 0, 0);
}

html.mm-opening.kingster-mmenu-right .kingster-mobile-header-wrap .kingster-top-bar,
html.mm-opening.kingster-mmenu-right .kingster-mobile-header {
	-webkit-transform: translate3d(-350px, 0, 0);
	transform: translate3d(-350px, 0, 0);
}

@media all and (max-width:550px) {
	html.mm-opening.kingster-mmenu-left .kingster-sticky-navigation.kingster-fixed-navigation {
		-webkit-transform: translate3d(250px, 0, 0);
		transform: translate3d(250px, 0, 0);
	}
	html.mm-opening.kingster-mmenu-right .kingster-sticky-navigation.kingster-fixed-navigation {
		-webkit-transform: translate3d(-250px, 0, 0);
		transform: translate3d(-250px, 0, 0);
	}
	html.mm-opening.kingster-mmenu-left .kingster-mobile-header-wrap .kingster-top-bar,
	html.mm-opening.kingster-mmenu-left .kingster-mobile-header {
		-webkit-transform: translate3d(250px, 0, 0);
		transform: translate3d(250px, 0, 0);
	}
	html.mm-opening.kingster-mmenu-right .kingster-mobile-header-wrap .kingster-top-bar,
	html.mm-opening.kingster-mmenu-right .kingster-mobile-header {
		-webkit-transform: translate3d(-250px, 0, 0);
		transform: translate3d(-250px, 0, 0);
	}
}


/*-----------------*/


/*--- superfish ---*/


/*-----------------*/


/* top level */

.sf-menu {
	list-style: none;
	margin-left: 0px;
}

.sf-menu>li {
	float: left;
	cursor: pointer;
}

.sf-menu>li>a {
	display: block;
	text-decoration: none;
	position: relative;
	z-index: 9;
}


/* normal menu */

.sf-menu>.kingster-normal-menu ul {
	list-style: none;
	position: absolute;
	display: none;
	z-index: 99;
	min-width: 220px;
}

.sf-menu>.kingster-normal-menu ul {
	margin-left: -2px;
}

.sf-menu>.kingster-normal-menu ul ul {
	margin-left: 0px;
}

.sf-menu>.kingster-normal-menu.sfHover>ul,
.sf-menu>.kingster-normal-menu.sfHover>ul:hover,
.sf-menu>.kingster-normal-menu li.sfHover>ul {
	display: block;
}

.sf-menu>.kingster-normal-menu ul ul {
	top: 0px;
	left: 100%;
}

.sf-menu>.kingster-normal-menu ul ul.sub-menu-right {
	left: auto;
	right: 100%;
}

.sf-menu>.kingster-normal-menu li {
	position: relative;
	white-space: nowrap;
}

.sf-menu>.kingster-normal-menu li a {
	text-decoration: none;
}

.sf-menu>.kingster-normal-menu li>a.sf-with-ul-pre {}

.sf-menu>.kingster-normal-menu li>a.sf-with-ul-pre:after {
	content: '>';
}


/* mega menu */

.sf-menu>.kingster-mega-menu .sf-mega {
	position: absolute;
	display: none;
	z-index: 99;
}

.sf-menu>.kingster-mega-menu.sfHover .sf-mega,
.sf-menu>.kingster-mega-menu.sfHover .sf-mega:hover {
	display: block;
}

.sf-menu>.kingster-mega-menu .sf-mega-section-inner a {
	text-decoration: none;
}

.sf-menu>.kingster-mega-menu .sf-mega-section-inner ul {
	list-style: none;
	margin-left: 0px;
	margin-bottom: 0px;
}


/* vertical menu */

.sf-vertical {
	list-style: none;
	margin-left: 0px;
	left: 100%;
	top: 0;
	margin-bottom: 0px;
}

.sf-vertical li {
	position: relative;
}

.sf-vertical ul.sub-menu {
	list-style: none;
	position: absolute;
	display: none;
	left: 100%;
	top: 0px;
	z-index: 9;
	min-width: 190px;
}

.sf-vertical ul.sub-menu li {
	white-space: nowrap;
}


/* event calendar */

#tribe-events .tribe-events-button,
.tribe-events-button {
	padding: 10px 20px;
}

.single-tribe_events .tribe-events-event-meta {
	background: #f5f5f5;
	border: none;
	padding: 30px 10px;
}

.tribe-events-meta-group .tribe-events-single-section-title {
	margin-bottom: 25px;
	font-size: 19px;
	color: #1f1f1f;
}

.single-tribe_events .tribe-events-event-meta dl {
	font-size: 15px;
	color: #1f1f1f;
}

.single-tribe_events .tribe-events-event-meta dt {
	margin-bottom: 8px;
	margin-top: 25px;
}

.single-tribe_events #tribe-events-content .tribe-events-event-meta dd.tribe-venue {
	font-weight: bold;
}

.single-tribe_events .secondary .tribe-events-venue-map {
	margin: -30px -10px;
	border: none;
}

body h1.tribe-events-page-title {
	font-size: 27px;
	margin-bottom: 50px;
	text-align: left;
}

body .tribe-bar-views-inner {
	background: #e0e0e0;
	padding: 19px 0px 45px;
}

.kingster-body h2.tribe-events-list-separator-month {
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
    padding: 15px 19px;
}
.kingster-body .tribe-events-list-separator-month+.type-tribe_events.tribe-events-first, .kingster-body .tribe-events-list .type-tribe_events {
    padding-top: 3px;
    padding-bottom: 50px;
}
.kingster-body span.tribe-event-date-start, .kingster-body span.tribe-event-date-end {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    color: #777;
}
.kingster-body h3.tribe-events-list-event-title {
    font-size: 24px;
    font-weight: 700;
}

.kingster-body .tribe-events-venue-details {
    line-height: 1.8;
    color: #555;
}
.kingster-body .tribe-events-list .tribe-events-loop .tribe-events-event-image {
    margin: 13px 3% 0 0;
    width: 36%;
}
.kingster-body a.tribe-events-read-more {
    background: #222;
    color: #fff;
    padding: 13px 22px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 12px;
    float: left;
}


.kingster-body ul.tribe-events-sub-nav {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 700;
}

.kingster-body table.tribe-events-calendar th {
    padding: 10px;
    font-size: 13px;
    font-weight: 700;
    border: none;
}

.kingster-body .tribe-events-notices {
    margin: 10px 0 34px;
}

.kingster-body div#tribe-events-header {
    padding-bottom: 10px;
}

.kingster-body div#tribe-events-footer {
    padding-top: 15px;
}

.kingster-body .tribe-bar-date-filter, .kingster-body .tribe-bar-search-filter, .kingster-body .tribe-bar-geoloc-filter, .kingster-body .tribe-bar-submit {
    letter-spacing: 2px;
    padding: 25px;
}

.kingster-body .tribe-bar-views-inner {
    background: #e0e0e0;
    padding: 30px 0px 50px 13px;
}

.kingster-body .tribe-bar-view-list a[class*=tribe-icon-], .kingster-body .tribe-bar-views-list span[class^=tribe-icon-], .kingster-body .tribe-select2-results-views span[class^=tribe-icon-] {
    margin-left: 14px;
    font-size: 15px;
}

.kingster-body #tribe-bar-form label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.kingster-body h2.tribe-events-page-title {
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-top: 20px;
    margin-bottom: 9px;
}

.kingster-body span.tribe-events-user-recurrence-toggle {
    margin-bottom: 30px;
}

.kingster-body input#tribeHideRecurrence {
    margin-top: -1px;
}

.kingster-body .tribe-events-user-recurrence-toggle label {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #666;
}

.kingster-body .tribe-events-event-details.tribe-clearfix {
    padding: 30px !important;
}

.kingster-body .tribe-event-schedule-details {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 15px;
}

.kingster-body h2.tribe-events-list-event-title {
    line-height: 1.4 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.kingster-body .tribe-events-list-photo-description.tribe-events-content {
    font-size: 15px;
    color: #333;
}

@media only screen and (max-width: 767px) {
    #gdlr-core-wrapper-1.gdlr-core-pbf-wrapper {
        margin-top: 0px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-1 .gdlr-core-pbf-column-content-margin {
        padding-top: 0px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-2 .gdlr-core-pbf-column-content-margin {
        padding-top: 0px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-3 .gdlr-core-pbf-column-content-margin {
        padding-top: 0px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-wrapper-2.gdlr-core-pbf-wrapper {
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-4 .gdlr-core-pbf-column-content-margin {
        padding-right: 30px !important;
        padding-left: 30px !important
    }
}

#gdlr-core-button-id-66813 {
    font-size: 16px;
    color: #fff;
    padding: 0px 0px 0px 0px;
    text-transform: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px
}

#gdlr-core-button-id-66813:hover {
    color: #3db166
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-5 .gdlr-core-pbf-column-content-margin {
        padding-top: 20px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-6 .gdlr-core-pbf-column-content-margin {
        padding-top: 20px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-7 .gdlr-core-pbf-column-content-margin {
        padding-top: 20px !important
    }
}

@media only screen and (max-width: 767px) {
    #gdlr-core-column-8 .gdlr-core-pbf-column-content-margin {
        padding-top: 70px !important;
        padding-bottom: 60px !important
    }
}

#gdlr-core-title-item-id-66469 .gdlr-core-title-item-title a {
    color: #3db166
}

#gdlr-core-title-item-id-66469 .gdlr-core-title-item-title a:hover {
    color: #59c17a
}

#gdlr-core-title-item-id-42777 .gdlr-core-title-item-title a {
    color: #3db166
}

#gdlr-core-title-item-id-42777 .gdlr-core-title-item-title a:hover {
    color: #59c17a
}

#gdlr-core-title-item-id-51281 .gdlr-core-title-item-title a {
    color: #3db166
}

#gdlr-core-title-item-id-51281 .gdlr-core-title-item-title a:hover {
    color: #59c17a
}

#gdlr-core-title-item-id-78243 .gdlr-core-title-item-title a {
    color: #3db166
}

#gdlr-core-title-item-id-78243 .gdlr-core-title-item-title a:hover {
    color: #59c17a
}

#gdlr-core-title-item-id-14842 .gdlr-core-title-item-title a {
    color: #3db166
}

#gdlr-core-title-item-id-14842 .gdlr-core-title-item-title a:hover {
    color: #59c17a
}

#gdlr-core-title-item-id-33183 .gdlr-core-title-item-title a {
    color: #3db166
}

#gdlr-core-title-item-id-33183 .gdlr-core-title-item-title a:hover {
    color: #59c17a
}

@media only screen and (max-width: 767px) {
    #gdlr-core-wrapper-3.gdlr-core-pbf-wrapper {
        padding-top: 0px !important
    }
}

.megaimg {
	background-position: bottom right;  
	background-repeat: no-repeat;
}

.filter ul {
	margin: 0 auto 40px;
	display: table;
}

.filter li {
	display: inline;
	list-style: none;
	margin: 0 15px;
}

.filter li:first-child {
	margin-left: 0;
}
.filter li:last-child {
	margin-right: 0;
}

.filter a {
	padding-bottom: 10px;
	text-transform: capitalize;
	color: #163269 !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    font-family: "Poppins", sans-serif;
}

.filter a.active {
	color: #3db166 !important;
}



.input1 {
	border: 0 !important;
	padding: 20px 10px !important;
	color: #333;
}

.submit-button { 
	font-size: 15px; 
	font-weight: bold; 
	letter-spacing: 0.8px; 
	padding: 15px; 
	width: 100%;
	display: block; -webkit-appearance: none; 
	background-color:#af938f; 
	border: 0;
	color: #fff;
	font-style: normal !important;
	cursor: pointer;
	font-style: normal;
}


.gdlr-core-portfolio-thumbnail-image-wrap img {
	width: 100%;
}



.input1 {
	border: 0 !important;
	padding: 20px 10px !important;
	color: #333;
}

.submit-button { 
	font-size: 15px; 
	font-weight: bold; 
	letter-spacing: 0.8px; 
	padding: 15px; 
	width: 100%;
	display: block; -webkit-appearance: none; 
	background-color:#3db166; 
	border: 0;
	color: #fff;
	font-style: normal !important;
	cursor: pointer;
	font-style: normal;
}
