$primary: #192f59;
$white: #ffff;
$yellow: #f9c80e;
$orange: #f86624;
$red: #ea3546;
$purple: #662e9b;
$secondary: #3db166;
$blue: #43bccd;
$default: #d1d5da;
$defaultBg: #2f363d;
$dark: #c9d1d9;
$darkBg: #0d1117;

.badge {
  display: inline-block;
  margin: 0.2rem;
  padding: 0.4rem 0.5rem;
  border-radius: 0.8rem;
  border: 1px solid transparent;
  font-size: 12px;

  &.badge-primary {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
}

.defaultBg {
  color: $defaultBg !important;
}

.secondary {
  color: $secondary !important;
}

.primary {
  color: $primary !important;
}

.danger {
  color: $red !important;
}

.doc-list-item {
  i {
    color: #192f59 !important;
  }

  &:hover {
    cursor: pointer;

    span {
      color: #60c988 !important;
    }

    i {
      color: #60c988 !important;
    }
  }
}

lightbox {
  z-index: 1000;
}


.side-menu {
  ul {
    list-style: none;

    li {
      cursor: pointer;
      position: relative;
      margin-top: 3px;
      padding: 0px;
      a {
        background: #3db166;
        display: block;
        margin: 0px;
        width: 100%;
        height: 100%;
        padding: 10px;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
        
        i {
          position: absolute;
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -10px;
        }

        &:hover, &.active {
          background: #192f59;
          color: #fff;
        }
      }
    }
  }
}
.side-iqac-menu {
  display: block;
}

.principal-image {
  height: 589px;
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  .principal-image {
    height: 389px;
  }
}


@media only screen and (max-width: 1260px) {
 .side-iqac-menu {
   display: none;
 }
 .iqac-content {
   width: 100%;
 }
}

.p-dialog-content {
  height: 600px !important;
  min-height: 600px !important;
}

.grade-naac {
  .p-dialog-content {
      height: auto !important;
      min-height: auto !important;
    }
    
}

.g-image-item {
  background-size: contain !important;
}

.user-link {
  &:hover {
    h3 {
      color: #3db166;
    }
  }
}